declare namespace ProductTypes {

  export interface Collection {
    handle: string
    description: string
    id: string
    isSubsCollection?: boolean
    imageForMobile:  Image | null
    imageForDesktop: Image | null
    products: Product[]
    title: string
  }

  export interface Image {
    src: string
  }

  export interface Product {
    createdAt: string
    descriptionHtml: string
    description: string
    productType: string
    handle: string
    id: string
    images: Image[]
    tags: string[]
    title: string
    variants: Variant[]
    vendor: string
    seo: {
      metaTitle: string | null
      metaDescription: string | null
    }
  }

  export interface Variant {
    availableForSale: boolean
    compareAtPrice: number | null
    id: string
    limitPerOrder: number
    isSubscription: boolean
    price: number
    product: Product
    sku: string
    title: string
    weight: number
  }

  export interface ZaiusItemProps {
    brand: string
    category_id: number
    data_source: string
    data_source_details: string
    data_source_instance: string
    data_source_type: string
    data_source_version: string
    handle: string
    image_url: string
    last_modified_at: number
    name: string
    parent_product_id: string
    price: string
    product_id: string
    product_type: string
    products_quantity: string
    published_at: number
    recommendation_type: string
    shopify_product_tags: string
    sku: null
    upc: null
  }

  export type ViewItemListEventProps = {
    listId: string,
    listName: string,
    items: Product[]
  }
}

export default ProductTypes

export enum VariantLimitType {
  PERSONALIZATION = 'personalization',
  QUANTITY = 'quantity',
  SUBS_WEIGHT = 'subsWeight'
}

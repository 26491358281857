interface StatusInfo {
  title: string
  message: string
  nextActiveStep: string
}

const profileModalTitlesAndMessages: { [unit: string]: StatusInfo } = {
  create_address: {
    title: 'Dirección guardada', message: 'Tu dirección ha sido guardada', nextActiveStep: 'personal_info'
  },
  create_card: {
    title: 'Tarjeta guardada', message: 'Tu tarjeta ha sido guardada', nextActiveStep: 'personal_info'
  },
  create_paypal: {
    title: 'PayPal guardado', message: 'Tu cuenta de PayPal ha sido guardada', nextActiveStep: 'personal_info'
  },
  remove_address: {
    title: 'Eliminar dirección de envío',
    message: 'Estás a punto de eliminar una dirección de envío, esta acción no se puede deshacer.',
    nextActiveStep: 'remove_address_confirmation'
  },
  remove_address_confirmation: {
    title: 'Dirección eliminada', message: 'Tu dirección ha sido eliminada', nextActiveStep: 'personal_info'
  },
  remove_card: {
    title: 'Eliminar tarjeta',
    message: 'Estás a punto de eliminar una tarjeta, esta acción no se puede deshacer.',
    nextActiveStep: 'remove_card_confirmation'
  },
  remove_card_confirmation: {
    title: 'Tarjeta eliminada', message: 'Tu tarjeta ha sido eliminada', nextActiveStep: 'personal_info'
  },
  assign_main_address: {
    title: 'Asignar como dirección principal', message: '', nextActiveStep: 'personal_info'
  },
  update_account_info: {
    title: 'Datos actualizados', message: 'Tus datos de perfil han sido actualizados', nextActiveStep: 'main_menu'
  },
  remove_paypal: {
    title: 'Eliminar cuenta de PayPal',
    message: 'Estás a punto de eliminar una cuenta de PayPal, esta acción no se puede deshacer.',
    nextActiveStep: 'remove_paypal_confirmation'
  },
  remove_paypal_confirmation: {
    title: 'PayPal eliminado', message: 'Tu cuenta de PayPal ha sido eliminada', nextActiveStep: 'personal_info'
  },
  logout: {
    title: 'Cerrar sesión', message: '¿Estás seguro que quieres cerrar sesión?', nextActiveStep: 'main_menu'
  },
  component: { title: '', message: '', nextActiveStep: 'subscriptions' },
  default: { title: '', message: '', nextActiveStep: 'main_menu' }
}

export default profileModalTitlesAndMessages

export type ProfileModalTitlesAndMessagesType = typeof profileModalTitlesAndMessages

const filterOptions = [
  { name: 'Promoción', tag: 'promotion', values: [], isOpen: true },
  { name: 'Tipo', tag: 'tipo', values: [], isOpen: false },
  { name: 'Estilo', tag: 'estilo', values: [], isOpen: false },
  { name: 'País', tag: 'pais', values: [], isOpen: false },
  { name: 'Marca', tag: 'brand', values: [], isOpen: false },
  { name: 'En suscripción', tag: 'subscription', values: [], isOpen: false }
] as { name: string, tag: string, values: { label: string, counter: number }[], isOpen: boolean }[]

export default filterOptions

type FilterOptionsType = typeof filterOptions

export { FilterOptionsType }

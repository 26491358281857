// Packages
import { useState } from 'react'

const useLocalStorage: (key: string, initialValue?: any) => [any, (value: any) => void] = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = typeof window !== 'undefined' && window.localStorage.getItem(key)
      const parsedItem = item ? JSON.parse(item) : null

      if (parsedItem?.timeToLive && Date.now() > parsedItem.timeToLive) {
        typeof window !== 'undefined' && window.localStorage.removeItem(key)
      }

      return parsedItem ? parsedItem : initialValue
    } catch (err)  {
      return initialValue
    }
  })

  const setValue: (value: any) => void = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      typeof window !== 'undefined' && window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch(err) {
      console.error(err)
    }
  }

  return [storedValue, setValue]
}

export default useLocalStorage

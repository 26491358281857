// Assets
import * as actionTypes from '../actionTypes'

// Types
import FluxTypes from '../../types/FluxTypes'
import ProductTypes from '../../types/ProductTypes'

// Assets
import { addToCartEvent, removeFromCartEvent } from '../../utilities/gtm'

export const addVariantToCart: FluxTypes.ActionCreator = (variant, quantity) => {
  const product: ProductTypes.Product = variant.product
  addToCartEvent(quantity, product)
  return {
    type: actionTypes.ADD_VARIANT_TO_CART,
    variant,
    quantity
  }
}

export const hideAllModals: FluxTypes.ActionCreator = () => (dispatch) => {
  dispatch(hideCart())
  dispatch(hideMainMenuPopover())
  dispatch(hideModalDialog())
  dispatch(setCurrentModalInfo({
    title: '',
    message: '',
    buttons: [],
    child: null,
    hide: null,
    errors: []
  }))
  dispatch(hideModalPLPFilters())
  dispatch(hideFinderPopover())
}

export const hideCart: FluxTypes.ActionCreator = () => ({
  type: actionTypes.UPDATE_CART_VISIBILITY,
  isCartVisible: false
})

export const hideMainMenuPopover: FluxTypes.ActionCreator = () => ({
  type: actionTypes.UPDATE_MAIN_MENU_POPOVER_VISIBILITY,
  isMainMenuPopoverVisible: false
})

export const removeVariantFromCart: FluxTypes.ActionCreator = (variant, quantity) => {
  const product: ProductTypes.Product = variant.product
  removeFromCartEvent(quantity, product)
  return {
    type: actionTypes.REMOVE_VARIANT_FROM_CART,
    id: variant.id,
    quantity
  }
}

export const showCart: FluxTypes.ActionCreator = () => (dispatch) => {
  dispatch(hideAllModals())
  return {
    type: actionTypes.UPDATE_CART_VISIBILITY,
    isCartVisible: true
  }
}

export const showMainMenuPopover: FluxTypes.ActionCreator = () => (dispatch) => {
  dispatch(hideAllModals())
  return {
    type: actionTypes.UPDATE_MAIN_MENU_POPOVER_VISIBILITY,
    isMainMenuPopoverVisible: true
  }
}

export const showModalDialog: FluxTypes.ActionCreator = () => (dispatch) => {
  dispatch(hideAllModals())
  return {
    type: actionTypes.UPDATE_MODAL_DIALOG_VISIBILITY,
    isModalDialogVisible: true
  }
}

export const hideModalDialog: FluxTypes.ActionCreator = () => (dispatch) => {
  dispatch(setCurrentModalInfo({
    title: '',
    message: '',
    buttons: [],
    child: null,
    hide: null,
    errors: []
  }))
  return {
    type: actionTypes.UPDATE_MODAL_DIALOG_VISIBILITY,
    isModalDialogVisible: false
  }
}


export const setCurrentModalDialogVisible: FluxTypes.ActionCreator = (value: string) => ({
  type: actionTypes.SET_CURRENT_MODAL_DIALOG_VISIBLE,
  currentModal: value
})

export const setCurrentModalInfo: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_CURRENT_MODAL_INFO,
  title: value.title,
  message: value.message,
  buttons: value.buttons,
  child: value.child,
  hide: value.hide,
  errors: value.errors
})

export const showModalPLPFilters: FluxTypes.ActionCreator = () => (dispatch) => {
  dispatch(hideAllModals())
  return {
    type: actionTypes.UPDATE_MODAL_PLP_FILTERS_VISIBILITY,
    isPLPFiltersVisible: true
  }
}

export const hideModalPLPFilters: FluxTypes.ActionCreator = () => ({
  type: actionTypes.UPDATE_MODAL_PLP_FILTERS_VISIBILITY,
  isPLPFiltersVisible: false
})

export const setAgeGateHasBeenAccepted: FluxTypes.ActionCreator = () => ({
  type: actionTypes.SET_AGE_GATE_HAS_BEEN_ACCEPTED
})

export const setSubscriptionGuideCompleted: FluxTypes.ActionCreator = () => ({
  type: actionTypes.SET_SUBSCRIPTION_GUIDE_COMPLETED
})

export const showFinderPopover: FluxTypes.ActionCreator = () => (dispatch) => {
  dispatch(hideAllModals())
  return {
    type: actionTypes.UPDATE_FINDER_POPOVER_VISIBILITY,
    isFinderPopoverVisible: true
  }
}

export const hideFinderPopover: FluxTypes.ActionCreator = () => ({
  type: actionTypes.UPDATE_FINDER_POPOVER_VISIBILITY,
  isFinderPopoverVisible: false
})

// --- Login
export const setInitialStateLogin: FluxTypes.ActionCreator = () => ({
  type: actionTypes.SET_LOGIN_INITIAL_STATE
})

export const setLoginEmail: FluxTypes.ActionCreator = (email) => ({
  type: actionTypes.SET_LOGIN_EMAIL,
  email
})

export const setLoginEmailErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_LOGIN_EMAIL_ERRORS,
  errors
})

export const setLoginPassword: FluxTypes.ActionCreator = (password) => ({
  type: actionTypes.SET_LOGIN_PASSWORD,
  password
})

export const setLoginPasswordErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_LOGIN_PASSWORD_ERRORS,
  errors
})

export const setLoginKeepSession: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.UPDATE_LOGIN_KEEP_SESSION,
  value
})

export const setLoginAcceptPolicyNConditions: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.UPDATE_LOGIN_ACCEPT_POLICY,
  value
})

export const setLoginAcceptPolicyNConditionsErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.UPDATE_LOGIN_ACCEPT_POLICY_ERRORS,
  errors
})

// --- recover password
export const setRecoverPasswordEmail: FluxTypes.ActionCreator = (email) => ({
  type: actionTypes.SET_RECOVER_PASSWORD_EMAIL,
  email
})

export const setRecoverPasswordEmailErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_RECOVER_PASSWORD_EMAIL_ERROS,
  errors
})

// --- Create account
export const setInitialStateCreateAccount: FluxTypes.ActionCreator = () => ({
  type: actionTypes.SET_CREATE_ACCOUNT_INITIAL_STATE
})

export const setCreateAccountName: FluxTypes.ActionCreator = (name) => ({
  type: actionTypes.SET_CREATE_ACCOUNT_NAME,
  name
})

export const setCreateAccountNameErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_CREATE_ACCOUNT_NAME_ERRORS,
  errors
})

export const setCreateAccountLastName: FluxTypes.ActionCreator = (lastName) => ({
  type: actionTypes.SET_CREATE_ACCOUNT_LAST_NAME,
  lastName
})

export const setCreateAccountLastNameErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_CREATE_ACCOUNT_LAST_NAME_ERRORS,
  errors
})

export const setCreateAccountEmail: FluxTypes.ActionCreator = (email) => ({
  type: actionTypes.SET_CREATE_ACCOUNT_EMAIL,
  email
})

export const setCreateAccountEmailErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_CREATE_ACCOUNT_EMAIL_ERRORS,
  errors
})

export const setCreateAccountPassword: FluxTypes.ActionCreator = (password) => ({
  type: actionTypes.SET_CREATE_ACCOUNT_PASSWORD,
  password
})

export const setCreateAccountPasswordErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_CREATE_ACCOUNT_PASSWORD_ERRORS,
  errors
})

export const setCreateAccountConfirmPassword: FluxTypes.ActionCreator = (confirmPassword) => ({
  type: actionTypes.SET_CREATE_ACCOUNT_CONFIRM_PASSWORD,
  confirmPassword
})

export const setCreateAccountConfirmPasswordErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_CREATE_ACCOUNT_CONFIRM_PASSWORD_ERRORS,
  errors
})

export const setCreateAccountBirthday: FluxTypes.ActionCreator = (birthday) => ({
  type: actionTypes.SET_CREATE_ACCOUNT_BIRTHDAY,
  birthday
})

export const setCreateAccountBirthdayErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_CREATE_ACCOUNT_BIRTHDAY_ERRORS,
  errors
})


export const setCreateAccountSubscribeNewsletter: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.UPDATE_CREATE_ACCOUNT_SUBSCRIBE_NEWSLETTER,
  value
})

export const setCreateAccountAcceptTerms: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.UPDATE_CREATE_ACCOUNT_ACCEPT_TERMS,
  value
})

export const setCreateAccountAcceptTermsErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.UPDATE_CREATE_ACCOUNT_ACCEPT_TERMS_ERRORS,
  errors
})

export const setCreateAccountBusinessRfc: FluxTypes.ActionCreator = (businessRfc) => ({
  type: actionTypes.SET_CREATE_ACCOUNT_BUSINESS_RFC,
  businessRfc
})

export const setCreateAccountBusinessRfcErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_CREATE_ACCOUNT_BUSINESS_RFC_ERRORS,
  errors
})

export const setCreateAccountBusinessName: FluxTypes.ActionCreator = (businessName) => ({
  type: actionTypes.SET_CREATE_ACCOUNT_BUSINESS_NAME,
  businessName
})

export const setCreateAccountBusinessNameErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_CREATE_ACCOUNT_BUSINESS_NAME_ERRORS,
  errors
})

export const setCreateAccountBusinessBillingAddress: FluxTypes.ActionCreator = (billingAddress) => ({
  type: actionTypes.SET_CREATE_ACCOUNT_BUSINESS_BILLING_ADDRESS,
  billingAddress
})

export const setCreateAccountBusinessBillingAddressErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_CREATE_ACCOUNT_BUSINESS_BILLING_ADDRESS_ERRORS,
  errors
})

export const setCreateAccountBusinessSap: FluxTypes.ActionCreator = (businessSap) => ({
  type: actionTypes.SET_CREATE_ACCOUNT_BUSINESS_SAP,
  businessSap
})

export const setCreateAccountBusinessSapErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_CREATE_ACCOUNT_BUSINESS_SAP_ERRORS,
  errors
})

// --- Customer info
export const setCustomerInfoEmail: FluxTypes.ActionCreator = (email) => ({
  type: actionTypes.SET_CUSTOMER_INFO_EMAIL,
  email
})

export const setCustomerInfoEmailErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_CUSTOMER_INFO_EMAIL_ERRORS,
  errors
})

export const setCustomerInfoBirthday: FluxTypes.ActionCreator = (birthday) => ({
  type: actionTypes.SET_CUSTOMER_INFO_BIRTHDAY,
  birthday
})

export const setCustomerInfoBirthdayErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_CUSTOMER_INFO_BIRTHDAY_ERRORS,
  errors
})

export const setCustomerInfoName: FluxTypes.ActionCreator = (name) => ({
  type: actionTypes.SET_CUSTOMER_INFO_NAME,
  name
})

export const setCustomerInfoNameErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_CUSTOMER_INFO_NAME_ERRORS,
  errors
})

export const setCustomerInfoLastName: FluxTypes.ActionCreator = (lastName) => ({
  type: actionTypes.SET_CUSTOMER_INFO_LAST_NAME,
  lastName
})

export const setCustomerInfoLastNameErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_CUSTOMER_INFO_LAST_NAME_ERRORS,
  errors
})

export const setCustomerInfoNamePlaceholder: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_CUSTOMER_INFO_NAME_PLACEHOLDER,
  value
})

export const setCustomerInfoLastNamePlaceholder: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_CUSTOMER_INFO_LAST_NAME_PLACEHOLDER,
  value
})

export const setCustomerInfoEmailPlaceholder: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_CUSTOMER_INFO_EMAIL_PLACEHOLDER,
  value
})

export const setCustomerInfoBusinessRfc: FluxTypes.ActionCreator = (businessRfc) => ({
  type: actionTypes.SET_CUSTOMER_INFO_BUSINESS_RFC,
  businessRfc
})

export const setCustomerInfoBusinessRfcErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_CUSTOMER_INFO_BUSINESS_RFC_ERRORS,
  errors
})

export const setCustomerInfoBusinessName: FluxTypes.ActionCreator = (businessName) => ({
  type: actionTypes.SET_CUSTOMER_INFO_BUSINESS_NAME,
  businessName
})

export const setCustomerInfoBusinessNameErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_CUSTOMER_INFO_BUSINESS_NAME_ERRORS,
  errors
})

export const setCustomerInfoBusinessBillingAddress: FluxTypes.ActionCreator = (billingAddress) => ({
  type: actionTypes.SET_CUSTOMER_INFO_BUSINESS_BILLING_ADDRESS,
  billingAddress
})

export const setCustomerInfoBusinessBillingAddressErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_CUSTOMER_INFO_BUSINESS_BILLING_ADDRESS_ERRORS,
  errors
})

export const setCustomerInfoBusinessSap: FluxTypes.ActionCreator = (businessSap) => ({
  type: actionTypes.SET_CUSTOMER_INFO_BUSINESS_SAP,
  businessSap
})

export const setCustomerInfoBusinessSapErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_CUSTOMER_INFO_BUSINESS_SAP_ERRORS,
  errors
})

export const setCustomerInfoChangePasswordNewPassword: FluxTypes.ActionCreator = (newPassword) => ({
  type: actionTypes.SET_CUSTOMER_INFO_CHANGE_PASSWORD_NEW_PASSWORD,
  newPassword
})

export const setCustomerInfoChangePasswordNewPasswordErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_CUSTOMER_INFO_CHANGE_PASSWORD_NEW_PASSWORD_ERRORS,
  errors
})

export const setCustomerInfoChangePasswordConfirmNewPassword: FluxTypes.ActionCreator = (confirmNewPassword) => ({
  type: actionTypes.SET_CUSTOMER_INFO_CHANGE_PASSWORD_CONFIRM_NEW_PASSWORD,
  confirmNewPassword
})

export const setCustomerInfoChangePasswordConfirmNewPasswordErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_CUSTOMER_INFO_CHANGE_PASSWORD_CONFIRM_NEW_PASSWORD_ERRORS,
  errors
})

// --- Shipping address
export const setShippingAddressName: FluxTypes.ActionCreator = (name) => ({
  type: actionTypes.SET_SHIPPING_ADDRESS_NAME,
  name
})

export const setShippingAddressNameErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_SHIPPING_ADDRESS_NAME_ERRORS,
  errors
})

export const setShippingAddressLastName: FluxTypes.ActionCreator = (lastName) => ({
  type: actionTypes.SET_SHIPPING_ADDRESS_LAST_NAME,
  lastName
})

export const setShippingAddressLastNameErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_SHIPPING_ADDRESS_LAST_NAME_ERRORS,
  errors
})

export const setShippingAddressAutocomplete: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_SHIPPING_ADDRESS_AUTOCOMPLETE,
  value
})

export const setShippingAddressAutocompleteErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_SHIPPING_ADDRESS_AUTOCOMPLETE_ERRORS,
  errors
})

export const setShippingAddressStreet: FluxTypes.ActionCreator = (street) => ({
  type: actionTypes.SET_SHIPPING_ADDRESS_STREET,
  street
})

export const setShippingAddressStreetErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_SHIPPING_ADDRESS_STREET_ERRORS,
  errors
})

export const setShippingAddressExteriorNumber: FluxTypes.ActionCreator = (exteriorNumber) => ({
  type: actionTypes.SET_SHIPPING_ADDRESS_EXTERIOR_NUMBER,
  exteriorNumber
})

export const setShippingAddressExteriorNumberErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_SHIPPING_ADDRESS_EXTERIOR_NUMBER_ERRORS,
  errors
})

export const setShippingAddressInteriorNumber: FluxTypes.ActionCreator = (interiorNumber) => ({
  type: actionTypes.SET_SHIPPING_ADDRESS_INTERIOR_NUMBER,
  interiorNumber
})

export const setShippingAddressInteriorNumberErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_SHIPPING_ADDRESS_EXTERIOR_NUMBER_ERRORS,
  errors
})

export const setShippingAddressZip: FluxTypes.ActionCreator = (zip) => ({
  type: actionTypes.SET_SHIPPING_ADDRESS_ZIP,
  zip
})

export const setShippingAddressZipErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_SHIPPING_ADDRESS_ZIP_ERRORS,
  errors
})

export const setShippingAddressNeighborhood: FluxTypes.ActionCreator = (neighborhood) => ({
  type: actionTypes.SET_SHIPPING_ADDRESS_NEIGHBORHOOD,
  neighborhood
})

export const setShippingAddressNeighborhoodErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_SHIPPING_ADDRESS_NEIGHBORHOOD_ERRORS,
  errors
})

export const setShippingAddressState: FluxTypes.ActionCreator = (state) => ({
  type: actionTypes.SET_SHIPPING_ADDRESS_STATE,
  state
})

export const setShippingAddressStateErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_SHIPPING_ADDRESS_STATE_ERRORS,
  errors
})

export const setShippingAddressCity: FluxTypes.ActionCreator = (city) => ({
  type: actionTypes.SET_SHIPPING_ADDRESS_CITY,
  city
})

export const setShippingAddressCityErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_SHIPPING_ADDRESS_CITY_ERRORS,
  errors
})

export const setShippingAddressMunicipality: FluxTypes.ActionCreator = (municipality) => ({
  type: actionTypes.SET_SHIPPING_ADDRESS_MUNICIPALITY,
  municipality
})

export const setShippingAddressMunicipalityErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_SHIPPING_ADDRESS_MUNICIPALITY_ERRORS,
  errors
})

export const setShippingAddressReferences: FluxTypes.ActionCreator = (references) => ({
  type: actionTypes.SET_SHIPPING_ADDRESS_REFERENCES,
  references
})

export const setShippingAddressReferencesErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_SHIPPING_ADDRESS_REFERENCES_ERRORS,
  errors
})

export const setShippingAddressPhone: FluxTypes.ActionCreator = (phone) => ({
  type: actionTypes.SET_SHIPPING_ADDRESS_PHONE,
  phone
})

export const setShippingAddressPhoneErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_SHIPPING_ADDRESS_PHONE_ERRORS,
  errors
})

export const setShippingAddressMainAddress: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.UPDATE_ADDRESS_MAINADDRESS,
  value
})

// --- Credit cards
export const setCardName: FluxTypes.ActionCreator = (name) => ({
  type: actionTypes.SET_CARD_NAME,
  name
})

export const setCardNameErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_CARD_NAME_ERRORS,
  errors
})

export const setCardNumber: FluxTypes.ActionCreator = (number) => ({
  type: actionTypes.SET_CARD_NUMBER,
  number
})

export const setCardNumberErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_CARD_NUMBER_ERRORS,
  errors
})

export const setCardListMonths: FluxTypes.ActionCreator = (months) => ({
  type: actionTypes.SET_CARD_LIST_MONTHS,
  months
})

export const setCardExpiryMonth: FluxTypes.ActionCreator = (month) => ({
  type: actionTypes.SET_CARD_EXPIRY_MONTH,
  month
})

export const setCardExpiryMonthErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_CARD_EXPIRY_MONTH_ERRORS,
  errors
})

export const setCardListYears: FluxTypes.ActionCreator = (years) => ({
  type: actionTypes.SET_CARD_LIST_YEARS,
  years
})

export const setCardExpiryYear: FluxTypes.ActionCreator = (year) => ({
  type: actionTypes.SET_CARD_EXPIRY_YEAR,
  year
})

export const setCardExpiryYearErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_CARD_EXPIRY_YEAR_ERRORS,
  errors
})

export const setCardCvv: FluxTypes.ActionCreator = (cvv) => ({
  type: actionTypes.SET_CARD_CVV,
  cvv
})

export const setCardCvvErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_CARD_CVV_ERRORS,
  errors
})

export const setSubscriptionFrequency: FluxTypes.ActionCreator = (frequency) => ({
  type: actionTypes.SET_SUBSCRIPTION_FREQUENCY,
  frequency
})

// Packages
import React from 'react'

// Assets
import authContext from '../../contexts/authContext'
import useProvideAuth from '../../hooks/useProvideAuth'

interface props {
  children: JSX.Element
}

const WithAuthContextProvider: React.FC<props> = ({ children }) => {
  const {
    user,
    signin,
    signup,
    signout,
    sendEmailToResetPassword,
    setCustomerAccessToken,
    refetchUser,
    loadingUser,
    customerType,
    confirmSignUp,
    resendConfirmationCode,
    sendNewPassword,
    getUser,
    updateUserAttributes,
    validateExpirationTokens,
    handleFederatedSignup,
    removeFederatedSignup,
    isNewFederatedAccount
  } = useProvideAuth()

  return (
    <authContext.Provider
      value={{
        user,
        signin,
        signup,
        signout,
        sendEmailToResetPassword,
        setCustomerAccessToken,
        refetchUser,
        loadingUser,
        customerType,
        confirmSignUp,
        resendConfirmationCode,
        sendNewPassword,
        getUser,
        updateUserAttributes,
        validateExpirationTokens,
        handleFederatedSignup,
        removeFederatedSignup,
        isNewFederatedAccount
      }}
    >
      {children}
    </authContext.Provider>
  )
}

export default WithAuthContextProvider

// Packages
import { createContext } from 'react'

// Assets
import intialState from '../../stores/formStore/initialState'

// Types
import FormStoreTypes from '../../types/FormStoreTypes'

const formStoreContext: FormStoreTypes.FormStoreContext = createContext({
  state: intialState
})

export default formStoreContext

// Types
import AppStoreTypes from '../../types/AppStoreTypes'

const initialState: AppStoreTypes.AppStoreState = {
  isCartVisible: false,
  isMainMenuPopoverVisible: false,
  isModalDialogVisible: false,
  isPLPFiltersVisible: false,
  ageGateHasBeenAccepted: false,
  isFinderPopoverVisible: false,
  subscriptionGuideCompleted: false,
  currentModalDialogVisible: '',
  currentModalInfo: {
    title: '',
    message: '',
    buttons: [],
    child: null,
    hide: null,
    errors: []
  }
}

const mergeLocalStorageStateIntoInitialState = (state: AppStoreTypes.AppStoreState) => {
  let newState = { ...state }

  let ageGateHasBeenAccepted: any = null
  let subscriptionGuideCompleted: any = null

  try {
    ageGateHasBeenAccepted = typeof window !== 'undefined' && window.localStorage.getItem('age_gate_accepted')
    ageGateHasBeenAccepted = ageGateHasBeenAccepted ? JSON.parse(ageGateHasBeenAccepted) : null
    subscriptionGuideCompleted = typeof window !== 'undefined' && window.localStorage.getItem('subscription_guide_completed')
    subscriptionGuideCompleted = subscriptionGuideCompleted ? JSON.parse(subscriptionGuideCompleted) : null
  } catch (e) {
    console.error(e)
  }

  if (ageGateHasBeenAccepted) {
    newState ={
      ...newState,
      ageGateHasBeenAccepted
    }
  }

  if (subscriptionGuideCompleted) {
    newState ={
      ...newState,
      subscriptionGuideCompleted
    }
  }
  return newState
}

const mergedInitialState = mergeLocalStorageStateIntoInitialState(initialState)

export default mergedInitialState

const customerMutationErrorCodes = {
  UNIDENTIFIED_CUSTOMER: 'No hemos podido encontrar las datos de la cuenta. Intentalo de nuevo.',
  BAD_DOMAIN: 'El correo electrónico contiene un nombre dominio no válido. Intentalo de nuevo.',
  CUSTOMER_DISABLED: 'Esta cuenta se escuentra deshabilitada. Intentalo de nuevo.',
  INVALID: 'El valor de algun elemento es invalido. Intentalo de nuevo.',
  INVALID_MULTIPASS_REQUEST: 'El token de esta cuenta es invalido. Intentalo de nuevo.',
  NOT_FOUND: 'Dirección de encontrada. Intentalo de nuevo.',
  TAKEN: 'Los datos ya han sido registrados previamente. Intentalo de nuevo.',
  TOKEN_INVALID: 'El token de esta cuenta es invalido. Intentalo de nuevo',
  TOO_LONG: 'El valor de algun elemento es demasiado largo. Intentalo de nuevo.',
  TOO_SHORT: 'El valor de algun elemento es demasiado corto. Intentalo de nuevo.',
  ALREADY_ENABLED: 'Error inesperado. Intentalo de nuevo.'
}

export default customerMutationErrorCodes

export type CustomerMutationErrorCodesType = typeof customerMutationErrorCodes

const mexicoStates = [
  { name: 'Selecciona un estado', shopifyCode: '' },
  { name: 'Aguascalientes', shopifyCode: 'AGS' },
  { name: 'Baja California', shopifyCode: 'BC' },
  { name: 'Baja California Sur', shopifyCode: 'BCS' },
  { name: 'Campeche', shopifyCode: 'CAMP' },
  { name: 'Chiapas', shopifyCode: 'CHIS' },
  { name: 'Chihuahua', shopifyCode: 'CHIH' },
  { name: 'Ciudad de México', shopifyCode: 'DF' },
  { name: 'Coahuila', shopifyCode: 'COAH' },
  { name: 'Colima', shopifyCode: 'COL' },
  { name: 'Durango', shopifyCode: 'DGO' },
  { name: 'Guanajuato', shopifyCode: 'GTO' },
  { name: 'Guerrero', shopifyCode: 'GRO' },
  { name: 'Hidalgo', shopifyCode: 'HGO' },
  { name: 'Jalisco', shopifyCode: 'JAL' },
  { name: 'México', shopifyCode: 'MEX' },
  { name: 'Michoacán', shopifyCode: 'MICH' },
  { name: 'Morelos', shopifyCode: 'MOR' },
  { name: 'Nayarit', shopifyCode: 'NAY' },
  { name: 'Nuevo León', shopifyCode: 'NL' },
  { name: 'Oaxaca', shopifyCode: 'OAX' },
  { name: 'Puebla', shopifyCode: 'PUE' },
  { name: 'Querétaro', shopifyCode: 'QRO' },
  { name: 'Quintana Roo', shopifyCode: 'Q ROO' },
  { name: 'San Luis Potosí', shopifyCode: 'SLP' },
  { name: 'Sinaloa', shopifyCode: 'SIN' },
  { name: 'Sonora', shopifyCode: 'SON' },
  { name: 'Tabasco', shopifyCode: 'TAB' },
  { name: 'Tamaulipas', shopifyCode: 'TAMPS' },
  { name: 'Tlaxcala', shopifyCode: 'TLAX' },
  { name: 'Veracruz', shopifyCode: 'VER' },
  { name: 'Yucatán', shopifyCode: 'YUC' },
  { name: 'Zacatecas', shopifyCode: 'ZAC' }
]

export default mexicoStates

export type MexicoStatesType = typeof mexicoStates

// Assets
import * as actionTypes from '../../actions/actionTypes'

// Types
import CartStoreTypes from '../../types/CartStoreTypes'
import FluxTypes from '../../types/FluxTypes'

const reducer: (state: CartStoreTypes.CartStoreState, action: FluxTypes.ActionObject) => CartStoreTypes.CartStoreState = (state, action) => {
  let newState = state

  let items: CartStoreTypes.Item[]

  switch (action.type) {

  case actionTypes.ADD_VARIANT_TO_CART: {
    items = state.items.slice()
    const index = items.findIndex((item) => item.variant.id === action.variant.id)
    const variantLimit = action.variant.limitPerOrder
    if (index === -1) {
      items.push({
        variant: action.variant,
        quantity: variantLimit && action.quantity >= variantLimit
          ? variantLimit
          : action.quantity
      })
    } else {
      items[index] = {
        variant: action.variant,
        quantity: variantLimit && items[index].quantity + action.quantity >= variantLimit
          ? variantLimit
          : items[index].quantity + action.quantity
      }
    }
    newState = { ...state, items }
    break
  }

  case actionTypes.REMOVE_VARIANT_FROM_CART:
    items = state.items.reduce<CartStoreTypes.Item[]>((items, item) => {
      if (item.variant.id !== action.id) {
        items.push(item)
        return items
      }
      if (action.quantity >= item.quantity) {
        return items
      }
      items.push({ variant: item.variant, quantity: item.quantity - action.quantity })
      return items
    }, [])
    newState = { ...state, items }
    break

  default:

  }

  typeof window !== 'undefined' && window.localStorage.setItem('cart', JSON.stringify(newState))

  return newState
}

export default reducer

// Packages
import _, { fromPairs, mapKeys, pick, flow, partial, keys, split } from 'lodash'
import fetch from 'cross-fetch'
import {
  type AuthTokens,
  type FetchUserAttributesOutput
} from 'aws-amplify/auth'

const now = new Date()

// Assets
import config from '../../data/config'
import {
  productSchema,
  GET_TRACKING_INFO,
  GET_ZIPCODE_SERVICE
} from '../../graphql/queries'
import { variantLimits } from '../../data/constants/shipping/index'

// Types
import TagsDictionaryTypes from '../../types/TagsDictionaryTypes'
import ProductTypes, { VariantLimitType } from '../../types/ProductTypes'
import CartStoreTypes from '../../types/CartStoreTypes'

export const generalIgnoredErrors = ['No current user']

export const cognitoErrorMessages: Record<string, any> = {
  CodeDeliveryFailureException: { es: 'El código de verificación no se ha entregado correctamente' },
  InvalidEmailRoleAccessPolicyException: { es: 'No se puede usar su identidad de correo electrónico' },
  InvalidParameterException: { es: 'Error en los parámetros enviados' },
  InvalidPasswordException: { es: 'La contraseña es incorrecta' },
  NotAuthorizedException: { es: 'El usuario o la contraseña son incorrectas' },
  ResourceNotFoundException: { es: 'No encontró el recurso solicitado' },
  TooManyRequestsException: { es: 'Ha realizado demasiadas solicitudes' },
  UsernameExistsException: { es: 'El usuario ya existe, inicie sesión para continuar' },
  AccessDeniedException: { es: 'No tienes acceso suficiente para realizar esta acción' },
  GENERIC_ERROR: { es: 'Se ha producido un error, inténtelo más tarde' },
  AliasExistsException: { es: 'Ya existe una cuenta con este correo' },
  CodeMismatchException: { es: 'El código es incorrecto' },
  ExpiredCodeException: { es: 'El código ha caducado' },
  UserNotConfirmedException: { es: 'Falta confirmar su correo' },
  UserNotFoundException: { es: 'No se encontró al usuario. Si tu cuenta fue creada con alguna red social debes crear tu cuenta con correo y contraseña.' },
  LimitExceededException: { es: 'Se superó el límite de intentos, inténtelo después de un tiempo' }
}

export const subscriptionStatus: Record<string, any> = {
  PAUSED: {
    es: {
      header: 'Pausada',
      subHeader: '',
      action: '',
      actionLink: ''
    },
    en: 'Paused'
  },
  PAUSED_BY_PAYMENT_ERROR: {
    es: {
      header: 'Pausada',
      subHeader: ' o revisa los fondos en tu tarjeta.',
      action: 'Cambia tu método de pago',
      actionLink: 'changePaymentMethod'
    },
    en: 'Paused'
  },
  PAUSED_BY_ADDRESS_ERROR: {
    es: {
      header: 'Pausada',
      subHeader: ' o modifica tu dirección de entrega.',
      action: 'Elige otra dirección',
      actionLink: 'changeAddress'
    },
    en: 'Paused'
  },
  PAUSED_BY_STOCKOUT: {
    es: {
      header: 'Pausada',
      subHeader: 'Por el momento no contamos con alguno de los productos de tu suscripción, elige otro.',
      action: '',
      actionLink: 'changeProduct'
    },
    en: 'Paused'
  },
  CANCELED: {
    es: {
      header: 'Cancelada',
      subHeader: '',
      action: '',
      actionLink: ''
    },
    en: 'Canceled'
  },
  ACTIVE: {
    es: {
      header: 'Activa',
      subHeader: '',
      action: '',
      actionLink: ''
    },
    en: 'Active'
  }
}

export const subscriptionFrequency: Record<string, any> = {
  FIFTEEN_DAYS: { es: '15 días', en: '15 days' },
  ONE_MONTH: { es: '1 mes', en: '1 month' },
  TWO_MONTHS: { es: '2 meses', en: '2 months' },
  THREE_MONTHS: { es: '3 meses', en: '3 month' }
}

export const subscriptionTypeCard = ( type: string ): string => {
  if (type.toLowerCase().includes( 'visa' )) return 'Visa'
  if (type.toLowerCase().includes( 'mastercard' )) return 'Mastercard'
  if (type.toLowerCase().includes( 'amex' )) return 'AMEX'
  return ''
}

export const findLimitOnVariant = (items: CartStoreTypes.Item[]): { id: string; limit: number } => {
  const limitedVariable = items.find((item) => {
    return item.variant.limitPerOrder && item.quantity >= item.variant.limitPerOrder
  })
  return {
    id: limitedVariable ? limitedVariable.variant.id : '',
    limit: limitedVariable ? limitedVariable.quantity : 0
  }
}

export const displayLimitReachedMessage: (
  limitPerOrder: number,
  variantLimitCause: VariantLimitType
) => string = (limitPerOrder, variantLimitCause) => {
  if (variantLimitCause === variantLimits.PERSONALIZATION) {
    return `El límite para ese producto personalizado es de  ${limitPerOrder}.`
  }
  if (variantLimitCause === variantLimits.QUANTITY) {
    return `Solamente puedes llevar ${limitPerOrder} packs de este producto por compra.`
  }
  if (variantLimitCause === variantLimits.SUBS_WEIGHT) {
    return `El límite de botellas en una sola suscripción es de ${limitPerOrder} botellas.`
  }
  return 'Has alcanzado el límite de productos.'
}

export const extractIdFromStorefrontId: ( storefrontId: string ) => number | boolean = (storefrontId) => {
  const uft8StoreFrontId = storefrontId.match( /^gid:\/\/shopify\/\D+(\d+)$/ )
  if (uft8StoreFrontId) {
    return parseInt( uft8StoreFrontId[1] )
  }

  const match = window.atob( storefrontId ).match( /^gid:\/\/shopify\/\D+(\d+)$/ )
  if (!match) {
    return false
  }
  return parseInt( match[1] )
}

export const encodeStorefrontId: ( type: string, id: string ) => string | undefined = ( type, id ) => {
  if (typeof window !== 'undefined') {
    return window.btoa( `gid://shopify/${type}/${id}` )
  }
}

export const formatCurrency: ( amount: number ) => string = (amount) => {
  return `$${formatNumber( amount, 2 )}`
}

export const formatNumber: ( amount: number, decimalCount?: number ) => string = ( amount, decimalCount = 0 ) => {
  return amount.toFixed( decimalCount ).replace( /\d(?=(\d{3})+(\.|$))/g, '$&,' )
}

export const formatDate: ( date: Date ) => string = (date) => {
  const dd = String( date.getDate() ).padStart( 2, '0' )
  const mm = String( date.getMonth() + 1 ).padStart( 2, '0' )
  const yyyy = date.getFullYear()
  return dd + '/' + mm + '/' + yyyy
}

export const formatDateAndHour: ( date: Date ) => string = (date) => {
  const dd = String( date.getDate() ).padStart( 2, '0' )
  const mm = String( date.getMonth() + 1 ).padStart( 2, '0' )
  const yyyy = date.getFullYear()
  const hr = String( date.getUTCHours() ).padStart( 2, '0' )
  const min = String( date.getMinutes() ).padStart( 2, '0' )
  return dd + '/' + mm + '/' + yyyy + ' | ' + hr + ':' + min + ' hrs'
}

export const getTags: ( values: string[], tagsDictionary: TagsDictionaryTypes.TagsObject ) => _.Dictionary<any> = ( values, tagsDictionary ) => {
  type KeysOfTagsObject =
    'estilo'
    | 'subestilo'
    | 'sabor1'
    | 'sabor2'
    | 'color'
    | 'volumen'
    | 'abv'
    | 'ibu'
    | 'cuerpo'
    | 'pais'
    | 'brand-page'
    | 'maridaje'
    | 'top-ten-position'
    | 'onlyLeftItems'

  const currentTagsFlow = flow( fromPairs )( values.map( partial( split, _ as any, ':', 2 as any ) ) )

  const listOfTagsKey = pick( currentTagsFlow, keys( tagsDictionary ) )

  const parseListOfTagsKey = mapKeys( listOfTagsKey, ( _, key: KeysOfTagsObject ) => tagsDictionary[key] )

  return parseListOfTagsKey
}

export const formatVariantButtonTitle: ( variantTitle: string ) => string | number = (variantTitle) => isSingleVariantTitle( variantTitle )
  ? '1'
  : isNaN( parseInt( variantTitle ) )
    ? variantTitle.substr( 0, 3 )
    : parseInt( variantTitle ).toString( 10 )

export const isSingleVariant: ( variant: ProductTypes.Variant ) => boolean = (variant) => isSingleVariantTitle( variant.title )

export const isSingleVariantTitle: ( variantTitle: string ) => boolean = (variantTitle) => ['default title', 'single', '1'].indexOf( variantTitle.toLowerCase() ) !== -1

export const testPasswordStrength: ( value: string ) => number = (value) => {
  /**
   * Strong regex rules
   * // At least one upper case English letter, (?=.*?[A-Z])
   * // At least one lower case English letter, (?=.*?[a-z])
   * // At least one digit, (?=.*?[0-9])
   * // At least one special character, (?=.*?[#?!@$%^&*-])
   * // Minimum eight in length .{8,} (with the anchors)
   */

  if (value === '') return 0

  const strongRegex = new RegExp( '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$' )
  const mediumRegex = new RegExp( '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})' )

  let lvl: number

  if (strongRegex.test( value )) {
    lvl = 3
  } else if (mediumRegex.test( value )) {
    lvl = 2
  } else {
    lvl = 1
  }

  return lvl
}

export const handlerErrorCognito: (
  errorResponse: { code?: string, name?: string }
) => any = (errorResponse): string => {
  if (errorResponse?.code) {
    const message = Object.keys(cognitoErrorMessages).includes(errorResponse.code)
      ? cognitoErrorMessages[errorResponse.code].es
      : null
    if (message) return message
  }

  if (errorResponse?.name) {
    const message = Object.keys(cognitoErrorMessages).includes(errorResponse.name)
      ? cognitoErrorMessages[errorResponse.name].es
      : null
    if (message) return message
  }

  return cognitoErrorMessages.GENERIC_ERROR.es
}

export const diacriticsScape: ( chain: string ) => string = (chain) => {
  let value = chain.toLowerCase()
  value = value === 'cdmx' ? 'DF' : value
  value = value === 'q.r.' ? 'Q ROO' : value
  value = value.replace( '.', '' )
  value = value.replace( new RegExp( '[àáâãäå]', 'g' ), 'a' )
  value = value.replace( new RegExp( '[èéêë]', 'g' ), 'e' )
  value = value.replace( new RegExp( '[ìíîï]', 'g' ), 'i' )
  value = value.replace( new RegExp( '[òóôõö]', 'g' ), 'o' )
  value = value.replace( new RegExp( '[ùúûü]', 'g' ), 'u' )
  return value
}

export const zaiusProductsRecommendationsCallback: () => Promise<any[]> = async () => {
  try {
    const url = `${config.zaiusApi.url}${config.zaiusApi.productsRecommendationsEndpoint}?limit=16`

    const res = await fetch(url, {
      method: 'get',
      headers: {
        'content-type': 'application/json',
        'x-api-key': config.zaiusApi.apiKey
      }
    })
    const response = await res.json()
    const filteredResponse = response.filter((item: ProductTypes.ZaiusItemProps) => {
      return item.published_at
    })
    return filteredResponse
  } catch (error) {
    return []
  }
}

export const getProductByIdCallback: ( productId: string ) => Promise<any> = async (productId) => {
  const res = await fetch( `https://${config.shopify.subdomain}.myshopify.com/api/${config.shopify.apiVersion}/graphql.json`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': config.shopify.accessToken
    },
    body: JSON.stringify( {
      query: `
        query ($id: ID!, $productImagesSize: Int = 312, $firsImagesProduct: Int = 1) {
          product: node(id: $id) {
            ... on Product ${productSchema}
          }
        }
      `,
      variables: { id: productId }
    } )
  } )
  const response = await res.json()
  return response
}

export const removeAccents: ( word: string ) => string = (word) => {
  return word
    .replace( /[áàãâä]/gi, 'a' )
    .replace( /[éè¨ê]/gi, 'e' )
    .replace( /[íìïî]/gi, 'i' )
    .replace( /[óòöôõ]/gi, 'o' )
    .replace( /[úùüû]/gi, 'u' )
    .replace( /[ç]/gi, 'c' )
    .replace( /[ñ]/gi, 'n' )
    .replace( /[^a-zA-Z0-9]/g, ' ' )
}

export const getProductVariantByIdCallback: ( productVariantId: string ) => Promise<any> = async (productVariantId) => {
  const res = await fetch( `https://${config.shopify.subdomain}.myshopify.com/api/${config.shopify.apiVersion}/graphql.json`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': config.shopify.accessToken
    },
    body: JSON.stringify( {
      query: `
        query ($productVariantId: ID!) {
          productVariant: node(id: $productVariantId) {
            ... on ProductVariant {
              availableForSale
            }
          }
        }
      `,
      variables: { productVariantId }
    } )
  } )
  const response = await res.json()
  return response
}

export const getTrackingAPI: ( orderNumber: string, email: string ) => Promise<any> = async ( orderNumber, email ) => {
  const res = await fetch( `https://${config.bhGraphQLServices.baseUrl}/graphql`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify( {
      query: GET_TRACKING_INFO,
      variables: {
        input: {
          order_name: `${orderNumber}`,
          email: email
        }
      }
    } )
  } )
  const response = await res.json()
  return response
}

export const getZipCodeService: ( zipCode: string ) => any = async ( zipcode ) => {
  try {
    const res = await fetch( `https://${config.bhGraphQLServices.baseUrl}/graphql`, {
      method: 'post',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify( {
        query: GET_ZIPCODE_SERVICE,
        variables: { zipcode }
      } )
    } )
    const response = await res.json()
    const { data: { zipcodeService: zipcodeServiceResponse } } = response

    return {
      success: true,
      zipcodeServiceResponse
    }
  } catch (e: any) {
    return {
      success: false,
      errors: e.message
    }
  }
}

export const PIN_LENGTH = 6

export const MINIMUM_CHARACTERS_PASSWORD = 6

export const INPUT_ERROR_CONTROL = {
  NONE: '',
  SUCCESS: 'success',
  ERROR: 'error'
}

export const STATUS_MODAL_POSTAL_CODE = {
  INITIAL: {
    STATUS: 'INITIAL',
    MESSAGE: 'Este nos ayudará a mostrarte la disponibilidad de productos cerca de ti.'
  },
  SAVED_ADDRESS: {
    STATUS: 'SAVED_ADDRESS',
    MESSAGE: '¡Código postal guardado!'
  },
  ZIP_CODE_ALREADY_EXISTS: {
    STATUS: 'ZIP_CODE_ALREADY_EXISTS',
    MESSAGE: 'Ya poseé este código postal en sus direcciones'
  },
  NO_COVERAGE_AREA: {
    STATUS: 'NO_COVERAGE_AREA',
    MESSAGE: 'Sin área de cobertura para compras con suscripción'
  },
  DEFAULT_ERROR: {
    MESSAGE: 'Se ha producido un error al guardar el código postal'
  }
}

export const formattedStoreFrontTokenCognito = ( data: AuthTokens ): any => {
  if (
    data?.idToken?.payload?.storefront_token &&
    data?.idToken?.payload?.storefront_token_expiration
  ) {
    return {
      accessToken: data.idToken.payload.storefront_token,
      expiresAt: data.idToken.payload.storefront_token_expiration
    }
  }

  return null
}

export const formattedCheckoutTokenCognito = ( data: AuthTokens ): any => {
  if (
    data?.idToken?.payload?.checkout_token &&
    data?.idToken?.payload?.checkout_token_expiration
  ) {
    return {
      accessToken: data.idToken.payload.checkout_token,
      expiresAt: data.idToken.payload.checkout_token_expiration
    }
  }

  return null
}

export const isJsonString = ( str: string ): boolean => {
  try {
    JSON.parse( str )
  } catch (e) {
    return false
  }
  return true
}

export const isStringWithLineBreak = ( str: string ): boolean => {
  try {
    return str.search( /(\r\n|\n|\r)/gm ) != -1
  } catch (e) {
    return false
  }
}

export const formattedCognitoCustomAttributes = ( session?: FetchUserAttributesOutput ): Record<string, never> => {
  let data = {}
  const attributes = session?.['custom:customAttributes']
    ? session['custom:customAttributes']
    : {}
  try {
    if (!attributes || typeof attributes !== 'string') return data
    if (isJsonString(attributes)) {
      data = JSON.parse( attributes)
    } else if (isStringWithLineBreak(attributes)) {
      const temporalData = attributes.replaceAll( /(\r\n|\n|\r)/gm, ' ' )
      data = isJsonString( temporalData ) ? JSON.parse( temporalData ) : {}
    }

    return data
  } catch (error) {
    return data
  }
}

export const getExpiryMonthOptions: ( actualMonth: number ) => any[] = ( actualMonth = 0 ) => {
  const months = []
  for (let i = actualMonth; i < 12; i++) {
    months.push(i)
  }
  const expiryMonthOptions: { label: any, value: any }[] = [{ label: 'Mes', value: '' }].concat(
    Array.from( months ).map( (i) => ( {
      label: _.padStart( ( i + 1 ).toString(), 2, '0' ),
      value: _.padStart( ( i + 1 ).toString(), 2, '0' )
    } ) )
  )
  return expiryMonthOptions
}

export const getExpiryYearOptions: () => any[] = () => {
  const expiryYearOptions: any[] = [{ label: 'Año', value: '' }].concat(
    Array.from( Array( 9 ).keys() ).map( (i) => ( {
      label: ( now.getFullYear() + i ).toString( 10 ),
      value: ( now.getFullYear() + i ).toString( 10 )
    } ) )
  )
  return expiryYearOptions
}

export const detectCardType: ( cardNumber: string ) => string | boolean = (cardNumber) => {
  if (/^4[0-9]{12}(?:[0-9]{3})?$/.test( cardNumber )) {
    return '001'
  } else if (
    /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/.test(
      cardNumber
    )
  ) {
    return '002'
  } else if (/^3[47][0-9]{13}$/.test( cardNumber )) {
    return '003'
  }
  return false
}

export const savedSubscription = (
  subscriptionProducts: any[],
  SUBSCRIPTION_PRODUCT_TAG: string
): string => {
  let amount = 0
  subscriptionProducts.map( ( subscriptionProduct: { variant: { product: { variants: any[] }; title: string; price: number }; quantity: number } ) => {
    const singleProductPack = subscriptionProduct?.variant?.product?.variants.find( ( item: { title: string } ) => (
      !item.title.includes( SUBSCRIPTION_PRODUCT_TAG ) &&
      item.title.replace( SUBSCRIPTION_PRODUCT_TAG, '' ) === subscriptionProduct?.variant?.title.replace( SUBSCRIPTION_PRODUCT_TAG, '' )
    ) )
    if (singleProductPack) {
      amount += ( singleProductPack?.price ? singleProductPack?.price : 0 ) - ( subscriptionProduct.quantity * subscriptionProduct.variant.price )
    } else {
      const singleProduct = subscriptionProduct.variant.product.variants.find( ( item: { title: string } ) => (
        item.title === '1'
      ) )
      amount += subscriptionProduct.variant.price - ( ( singleProduct?.price ? singleProduct?.price : 1 ) * parseInt( subscriptionProduct?.variant?.title.replace( SUBSCRIPTION_PRODUCT_TAG, '' ) ) )
    }
  } )

  return amount > 0 ? ` ${amount}` : ''
}

export const savingsWithSubscription = (
  product: { variant: { product: { variants: any[] }; title: string; price: number }; quantity: number },
  SUBSCRIPTION_PRODUCT_TAG: string
): string => {
  let savings = 0
  const productSubscription = product?.variant?.product?.variants.filter( ( item: { title: string } ) => (
    item.title.includes( SUBSCRIPTION_PRODUCT_TAG )
  ) )
  const subscriptionProductPack = product?.variant?.product?.variants.find( ( item: { title: string } ) => (
    item.title.includes( SUBSCRIPTION_PRODUCT_TAG ) && item.title.replace( SUBSCRIPTION_PRODUCT_TAG, '' ) === product?.variant?.title
  ) )
  if (subscriptionProductPack) {
    savings = ( product?.quantity * product?.variant?.price ) - ( product?.quantity * subscriptionProductPack?.price )
  } else {
    const beerPackQuantity = parseInt( productSubscription[0]?.title.replace( SUBSCRIPTION_PRODUCT_TAG, '' ) )
    savings = ( product?.variant?.price * beerPackQuantity ) - productSubscription[0]?.price
  }

  return savings > 0 ? `y Ahorra $${savings}` : ''
}

interface TagsProps {
  label: string
  counter: number
}

export const getTagsWithAccents = (tags: TagsProps[]): TagsProps[] => {
  const tagsUsedAsFilters = tags.filter((tag) => tag.label.split(':')[1])
  const tagsWithAccents = tagsUsedAsFilters.filter((tag) => {
    return tag.label.split(':')[1] !== removeAccents(tag.label.split(':')[1])
  })

  const correctedTagsWithAccents = tags.map((tag) => {
    const _tag = { ...tag }
    const _tagLabelValue = _tag.label.split(':')[1]
    if (_tagLabelValue) {
      if (_tagLabelValue === removeAccents(_tagLabelValue)) {
        const accentMatch = tagsWithAccents.find((accentedTag) => {
          return removeAccents(accentedTag.label.split(':')[1]) === _tagLabelValue
        })
        if (accentMatch && accentMatch?.label.split(':')[0] === _tag.label.split(':')[0]) {
          _tag.label = accentMatch.label
          _tag.counter = _tag.counter + accentMatch.counter
        }
      }
    }
    return _tag
  })

  const finalTags: TagsProps[] = []

  correctedTagsWithAccents.forEach((tag) => {
    const alreadyOnFinalTags = finalTags.filter((finalTag) => finalTag.label === tag.label)
    if (alreadyOnFinalTags.length === 0) finalTags.push(tag)
  })
  return finalTags
}

export const b64ToUtf8 = (str: string): string => {
  try {
    return decodeURIComponent(window.atob(str))
  } catch (error) {
    return ''
  }
}

export const getCompareAtPrice = (price: { amount: string | null } |  string): number | null => {
  if (typeof price === 'number' || typeof price === 'string') {
    return parseFloat(price)
  } else if (typeof price === 'object') {
    return price?.amount ? parseFloat(price.amount) : null
  }

  return null
}

export const getPrice = (price: { amount: string } |  string): number => {
  if (typeof price === 'number' || typeof price === 'string') {
    return parseFloat(price)
  } else {
    return parseFloat(price.amount)
  }
}

export const addMinutes = (date: Date, minutes: number): Date => {
  date.setMinutes(date.getMinutes() + minutes)

  return date
}

interface FilterVisibleVariantsProps {
  packVariants: ProductTypes.Variant[]
  subscriptionInfoVisible?: boolean
}

export const filterVisibleVariants = ({
  packVariants,
  subscriptionInfoVisible = false
}: FilterVisibleVariantsProps) => {
  const filteredVariants = packVariants.filter((variant) => {
    return (
      subscriptionInfoVisible === checkIfVariantIsSubs(variant)
    )
  })
  return filteredVariants
}

export const checkIfVariantIsSubs = (variant: ProductTypes.Variant) => (variant.isSubscription ? true : false)

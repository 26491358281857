// Packages
import { gql } from '@apollo/client'

export const productSchema = `
  {
    descriptionHtml
    description
    productType
    handle
    createdAt
    id
    images(first: $firsImagesProduct) {
      edges {
        image: node { transformedSrc(maxHeight: $productImagesSize, maxWidth: $productImagesSize) }
      }
    }
    tags
    title
    seo {
      title
      description
    }
    variants(first: 50) {
      edges {
        variant: node {
          availableForSale
          compareAtPrice {
            amount
          }
          id
          limitPerOrder: metafield(namespace:"my_fields", key: "limit_per_order") {
            value
          }
          isSubscription: metafield(namespace:"my_fields", key: "subscription") {
            value
          }
          price {
            amount
          }
          sku
          title
          weight
        }
      }
    }
    vendor
  }
`

export const collectionSchema = `
  handle
  id
  description
  products(first: 250, after: $after, sortKey: $sortKey) {
    edges {
      cursor
      product: node ${productSchema}
    }
    pageInfo {
      hasNextPage
    }

  }
  title
`

export const GET_COLLECTION_BY_ID_QUERY = gql`
  query ($id: ID!, $after: String, $sortKey: ProductCollectionSortKeys, $productImagesSize: Int = 312, $firsImagesProduct: Int = 1) {
    collection: node(id: $id) {
      ... on Collection {
        ${collectionSchema}
        imageForMobile: image {
          transformedSrc(maxWidth: 768, maxHeight: 350)
        }
        imageForDesktop: image {
          transformedSrc(maxWidth: 1440, maxHeight: 450)
        }
      }
    }
  }
`

export const GET_COLLECTION_BY_ID_QUERY_WITHOUT_IMAGE = gql`
  query ($id: ID!, $after: String, $sortKey: ProductCollectionSortKeys, $productImagesSize: Int = 312, $firsImagesProduct: Int = 1) {
    collection: node(id: $id) {
      ... on Collection {
        ${collectionSchema}
      }
    }
  }
`

export const GET_PRODUCT_BY_ID_QUERY = gql`
  query ($id: ID!, $productImagesSize: Int = 312, $firsImagesProduct: Int = 4) {
    product: node(id: $id) {
      ... on Product ${productSchema}
    }
  }
`

export const GET_CUSTOMER_QUERY = gql`
  query GetCustomer($accessToken: String!) {
    customer(customerAccessToken: $accessToken) {
      id
      displayName
      email
      firstName
      lastName
      phone
      acceptsMarketing
      tags
      defaultAddress {
        id
        firstName
        lastName
        address1
        address2
        city
        company
        country
        countryCodeV2
        phone
        province
        provinceCode
        zip
      }
      addresses(first: 10) {
        edges {
          node {
            id
            firstName
            lastName
            # STREET | EXTERIOR N | INTERIOR N
            address1
            # COLONY | REFERENCE
            address2
            city
            # DELEGACION || MUNICIPALITY
            company
            country
            countryCodeV2
            phone
            province
            provinceCode
            zip
          }
        }
      }
      orders(first: 50, reverse: true) {
        edges {
          node {
            processedAt
            orderNumber
            subtotalPrice {
              amount
            }
            totalShippingPrice {
              amount
            }
            totalPrice {
              amount
            }
            shippingAddress {
              firstName
              lastName
              address1
              address2
              city
              company
              country
              countryCodeV2
              phone
              province
              provinceCode
              zip
            }
            lineItems(first: 50) {
              edges {
                product: node {
                  quantity
                  title
                  variant {
                    availableForSale
                    id
                    image {
                      transformedSrc(maxHeight: 312, maxWidth: 312)
                    }
                    limitPerOrder: metafield(
                      namespace:"my_fields",
                      key: "limit_per_order"
                    ) {
                      value
                    }
                    isSubscription: metafield(
                      namespace: "my_fields"
                      key: "subscription"
                    ) {
                      value
                    }
                    title
                    price {
                      amount
                    }
                    product {
                      tags
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const SEARCH_COLLECTION_BY_QUERY_STRING = gql`
  query($query: String, $first: Int = 5, $sortKey: CollectionSortKeys) {
    collections(first: $first, query: $query, sortKey: $sortKey) {
      edges {
        collection: node {
          ... on Collection {
            handle
            id
            description
            title
          }
        }
      }
    }
  }
`

export const SEARCH_PRODUCT_BY_QUERY_STRING = gql`
  query ($query: String, $first: Int = 5, $sortKey: ProductSortKeys, $productImagesSize: Int = 312, $firsImagesProduct: Int = 1) {
    products(first: $first, query: $query, sortKey: $sortKey) {
      edges {
        product: node {
          ... on Product ${productSchema}
        }
      }
    }
  }
`

export const GET_PRODUCT_RECOMMENDATIONS = gql`
  query productRecommendations($productId: ID!, $productImagesSize: Int = 312, $firsImagesProduct: Int = 1) {
    productRecommendations(productId: $productId) {
      ... on Product ${productSchema}
    }
  }
`

export const GET_STOCKOUTS = gql`
  query getStockouts($input: [InputStockout!]) {
    stockouts(input: $input) {
      sku
      isStockout
    }
  }
`

export const GET_TRACKING_INFO = `
  query getOrderStatus($input: InputUserOrder!) {
    orderStatus(input: $input) {
      order_name
      has_results
      trackings {
        tracking_id
        courier
        status {
          name
          created_at
        }
      }
    }
  }
`

export const GET_ZIPCODE_SERVICE = `
  query getZipcodeService($zipcode: String!) {
    zipcodeService(zipcode: $zipcode) {
      zipcode
      district
      city
      suscription
    }
  }
`

export const GET_USER_CARD_TOKENS = `
  query {
    userCards {
      id
      cardId
      lastFourDigits
      paymentMethodId
      isDefault
    }
  }
`

export const CHECK_USER_PAYPAL_ACCOUNT = `
  query {
    userPaypal {
      billingAgreementId
      id
      payerId
      payerEmail
      isDefault
    }
  }
`

export const GET_PAYPAL_TOKENS = `
  query {
    paypalTokens {
      token
      billingAgreementToken
    }
  }
`

export const GET_SUBSCRIBER_INFORMATION = gql`
  query {
    me {
      email
      name
      birthDate
      subscriptions {
        id
        createdAt
        frequency
        status
        billingDate
        totalAmount
        paymentMethod {
          id
          isDefault
          ... on MercadoPagoCardPaymentMethod {
            cardId
            customerId
            cybersouceToken
            lastFourDigits
            firstSixDigits
            issuerId
            issuerName
            paymentMethodId
            expirationMonth
            expirationYear
          }
          ... on PayPalPaymentMethod {
            billingAgreementId
            payerId
            payerEmail
          }
        }
        address {
          id
          postalCode
          state
          locality
          neighborhood
          streetName
          streetNumber
          phone
        }
        items {
          id
          title
          size
          sku
          quantity
          originalPrice
          price
          imageUrl
        }
      }
      subscriptionsCanceled {
        id
        createdAt
        cancellationDate
      }
      paymentMethods {
        id
        isDefault
        ... on MercadoPagoCardPaymentMethod {
          cardId
          customerId
          cybersouceToken
          lastFourDigits
          firstSixDigits
          issuerId
          issuerName
          paymentMethodId
          expirationMonth
          expirationYear
        }
        ... on PayPalPaymentMethod {
          billingAgreementId
          payerId
          payerEmail
        }
      }
      addresses {
        id
        isDefault
        postalCode
        state
        locality
        neighborhood
        streetName
        streetNumber
        interiorNumber
        phone
        firstName
        lastName
        references: reference
        latitude
        longitude
        stateCode
        city
      }
    }
  }
`

import * as actionTypes from '../../actions/actionTypes'

// Types
import SubscriptionTypes from '../../types/SubscriptionTypes'
import FluxTypes from '../../types/FluxTypes'

const reducer: (
  state: SubscriptionTypes.SubscriptionStoreState,
  action: FluxTypes.ActionObject
) => SubscriptionTypes.SubscriptionStoreState = (state, action) => {
  let newState = state

  switch (action.type) {
  case actionTypes.SET_SUBSCRIPTION_FREQUENCY:
    newState = {
      ...state,
      frequency: action.frequency
    }
    break

  default:
  }

  typeof window !== 'undefined' && window.localStorage.setItem('subscription', JSON.stringify(newState))

  return newState
}

export default reducer

// Types
import { SortingOptions } from '../../../types/LandingPagesTypes'

const landingPageSortingOptions = [
  { key: SortingOptions.AlphabeticalAscending, label: 'Ordenar de la A - Z' },
  { key: SortingOptions.AlphabeticalDescending, label: 'Ordenar de la Z - A' }
]

export default landingPageSortingOptions

export type LandingPageSortingOptionsType = typeof landingPageSortingOptions

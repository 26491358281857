// Packages
import { toLower } from 'lodash'

// Types
import ProductTypes from '../../types/ProductTypes'
import UserTypes from '../../types/UserTypes'
import PredictiveSearchTypes from '../../types/PredictiveSearchTypes'

// Assets
import { getTags, getCompareAtPrice, getPrice } from '../general'
import tagsDictionary from '../../data/constants/tagsDictionary'
import noPhotoAvailable from '../../data/assets/img/no-photo-available.png'

export const transformStorefrontCollectionIntoCollectionType: (storefrontCollection: any) => ProductTypes.Collection = (storefrontCollection) => {
  const collection: ProductTypes.Collection = {
    handle: storefrontCollection.handle,
    description: storefrontCollection.description,
    id: storefrontCollection.id,
    imageForDesktop: storefrontCollection.imageForDesktop ? transformStorefrontImageIntoImageType(storefrontCollection.imageForDesktop) : null,
    imageForMobile: storefrontCollection.imageForMobile ? transformStorefrontImageIntoImageType(storefrontCollection.imageForMobile) : null,
    products: transformStorefrontProductsIntoProductsType(storefrontCollection.products),
    title: storefrontCollection.title
  }

  return collection
}

export const transformStorefrontProductsIntoProductsType: (storefrontProducts: any) => ProductTypes.Product[] = (storefrontProducts) => {
  let customerType = typeof window !== 'undefined' && window.localStorage.getItem('customer_type')
  customerType = customerType ? JSON.parse(customerType) : ''

  const filteredProducts = storefrontProducts.edges.filter(({ product: storefrontProducts }: { product: any }) => {
    const sku = storefrontProducts.variants.edges[0].variant.sku || ''
    if (customerType === 'B2B') {
      return storefrontProducts
    } else {
      return !sku.toLowerCase().includes('b2b')
    }
  })

  return filteredProducts.map(({ product: storefrontProduct }: { product: any }) => {
    return transformStorefrontProductIntoProductType(storefrontProduct)
  }) as ProductTypes.Product[]
}

export const transformStorefrontProductIntoProductType: (storefrontProduct: any) => ProductTypes.Product = (storefrontProduct) => {
  const product: ProductTypes.Product = {
    createdAt: storefrontProduct.createdAt,
    descriptionHtml: storefrontProduct.descriptionHtml,
    description: storefrontProduct.description,
    productType: storefrontProduct.productType,
    handle: storefrontProduct.handle,
    id: storefrontProduct.id,
    images: storefrontProduct.images.edges.length > 0 ? transformStorefrontImagesIntoImagesType(storefrontProduct.images) : [{ src: noPhotoAvailable }],
    tags: storefrontProduct.tags,
    title: storefrontProduct.title,
    variants: storefrontProduct.variants,
    vendor: storefrontProduct.vendor,
    seo: {
      metaTitle: storefrontProduct.seo.title,
      metaDescription: storefrontProduct.seo.description
    }
  }
  product.variants = transformStorefrontVariantsIntoVariantsType(storefrontProduct.variants, product)
  return product
}

export const transformStorefrontVariantsIntoVariantsType: (storefrontVariants: any, product: ProductTypes.Product) => ProductTypes.Variant[] = (storefrontVariants, product) => {
  const { variants: storefrontVariantsWithoutTransform, ...restOfProduct } = product

  return storefrontVariants.edges.map(({ variant: storefrontVariant }: { variant: any }) => {
    return transformStorefrontVariantIntoVariantType(storefrontVariant, storefrontVariantsWithoutTransform, restOfProduct)
  }) as ProductTypes.Variant[]
}

export const transformStorefrontVariantIntoVariantType: (storefrontVariant: any, storefrontVariantsWithoutTransform: any, product: Omit<ProductTypes.Product, 'variants'>) => ProductTypes.Variant = (storefrontVariant, storefrontVariantsWithoutTransform, product) => {
  const variant: ProductTypes.Variant = {
    availableForSale: storefrontVariant.availableForSale,
    compareAtPrice: getCompareAtPrice(storefrontVariant.compareAtPrice),
    id: storefrontVariant.id,
    price: getPrice(storefrontVariant.price),
    sku: storefrontVariant.sku,
    title: storefrontVariant.title,
    weight: storefrontVariant.weight,
    limitPerOrder: storefrontVariant.limitPerOrder?.value
      ? parseInt(storefrontVariant.limitPerOrder.value.slice(
        1,
        storefrontVariant.limitPerOrder.value.length - 1
      ))
      : 0,
    isSubscription: storefrontVariant?.isSubscription?.value === 'true' ? true : false,
    product: {
      variants: storefrontVariantsWithoutTransform.edges.map(({ variant }: { variant: any }) => {
        return {
          availableForSale: variant.availableForSale,
          compareAtPrice: getCompareAtPrice(variant.compareAtPrice),
          id: variant.id,
          price: getPrice(variant.price),
          sku: variant.sku,
          title: variant.title,
          limitPerOrder: variant.limitPerOrder?.value
            ? parseInt(variant.limitPerOrder.value.slice(
              1,
              variant.limitPerOrder.value.length - 1
            ))
            : 0,
          isSubscription: variant?.isSubscription?.value === 'true' ? true : false,
        }
      }),
      ...product
    }
  }
  return variant
}

export const transformStorefrontImagesIntoImagesType: (storefrontImages: any) => ProductTypes.Image[] = (storefrontImages) => {
  return storefrontImages.edges.map(({ image: storefrontImage }: { image: any }) => {
    return transformStorefrontImageIntoImageType(storefrontImage)
  }) as ProductTypes.Image[]
}

// --- User
export const transformStorefrontUserIntoUserType: (storefrontUser: any) => Promise<UserTypes.UserInfo> = async (storefrontUser) => {
  let user: UserTypes.UserInfo
  if (storefrontUser !== null) {
    user = {
      info: {
        id: storefrontUser.id,
        completeName: storefrontUser.displayName,
        email: storefrontUser.email,
        firstName: storefrontUser.firstName,
        lastName: storefrontUser.lastName,
        phone: storefrontUser.phone || null,
        acceptsMarketing: storefrontUser.acceptsMarketing,
        defaultAddress: storefrontUser.defaultAddress ? transformStorefrontUserAddressIntoUserAddressType(storefrontUser.defaultAddress) : null,
        addresses: transformStorefrontUserAddressesIntoUserAddressesType(storefrontUser.addresses),
        orders: transformStorefrontUserOrdersIntoUserOrdersType(storefrontUser.orders)
      }
    }
  } else {
    user = { errors: ['Not found'] }
  }

  return user
}

export const transformStorefrontUserAddressesIntoUserAddressesType: (storefrontUserAddresses: any) => null | UserTypes.Address[] = (storefrontUserAddresses) => {
  if(storefrontUserAddresses.edges.length > 0) {
    return storefrontUserAddresses.edges.map(({ node: storefrontAddress }: { node: any }) => {
      return transformStorefrontUserAddressIntoUserAddressType(storefrontAddress)
    }) as UserTypes.Address[]
  } else {
    return null
  }
}

export const transformStorefrontUserAddressIntoUserAddressType: (storefrontUserAddress: any) => UserTypes.Address = (storefrontUserAddress) => {
  const [street, exteriorNumber, interiorNumber] = storefrontUserAddress && storefrontUserAddress.address1 ? storefrontUserAddress.address1.split('|') : ''
  const [neighborhood, references] = storefrontUserAddress && storefrontUserAddress.address2 ? storefrontUserAddress.address2.split('|') : ''
  const userAddresses: UserTypes.Address = {
    id: storefrontUserAddress.id,
    firstName: storefrontUserAddress.firstName,
    lastName: storefrontUserAddress.lastName,
    street: street || null,
    exteriorNumber: exteriorNumber || null,
    interiorNumber: interiorNumber || null,
    neighborhood: neighborhood || null,
    references: references || null,
    city: storefrontUserAddress.city,
    municipality: storefrontUserAddress.company || null, // delegation or municipality
    country: storefrontUserAddress.country,
    countryCode: storefrontUserAddress.countryCodeV2,
    phone: storefrontUserAddress.phone || null,
    province: storefrontUserAddress.province || null,
    provinceCode: storefrontUserAddress.provinceCode || null,
    zip: storefrontUserAddress.zip
  }

  return userAddresses
}


export const transformStorefrontUserOrdersIntoUserOrdersType: (storefrontOrders: any) => UserTypes.Order[] | null = (storefrontOrders) => {
  if(storefrontOrders.edges.length > 0) {
    return storefrontOrders.edges.map(({ node: storefrontOder }: { node: any }) => {
      return transformStorefrontUserOrderIntoUserOrderType(storefrontOder)
    }) as UserTypes.Order[]
  } else {
    return null
  }
}

export const transformStorefrontUserOrderIntoUserOrderType: (storefrontOrder: any) => UserTypes.Order = (storefrontOrder) => {
  const order: UserTypes.Order = {
    processedAt: storefrontOrder.processedAt,
    orderNumber: storefrontOrder.orderNumber,
    shippingAddress: transformStorefrontUserAddressIntoUserAddressType(storefrontOrder.shippingAddress),
    shippingTotalPrice: parseFloat(storefrontOrder.totalShippingPrice.amount),
    productsTotalPrice: parseFloat(storefrontOrder.subtotalPrice.amount),
    grandTotalPrice: parseFloat(storefrontOrder.totalPrice.amount),
    lineItems: transformStorefrontUserOrderProductsIntoUserOrderProductsType(storefrontOrder.lineItems)
  }

  return order
}

export const transformStorefrontUserOrderProductsIntoUserOrderProductsType: (storefrontOrderProducts: any) => UserTypes.LineItem[] | null = (storefrontOrderProducts) => {
  const lineItems: UserTypes.LineItem[] = []

  storefrontOrderProducts.edges.forEach(({ product: orderLineItem }: { product: any }) => {
    orderLineItem.variant !== null && lineItems.push(transformStorefrontUserOrderProductIntoUserOrderProductType(orderLineItem))
  })

  return lineItems.length ? lineItems : null
}

export const transformStorefrontUserOrderProductIntoUserOrderProductType: (orderLineItem: any) => UserTypes.LineItem = (orderLineItem) => {
  const product: UserTypes.LineItem = {
    title: orderLineItem.title,
    quantity: orderLineItem.quantity,
    variant: transformStorefrontUserOrderProductVariantIntoUserOrderProductVariantType(orderLineItem.variant)
  }

  return product
}

export const transformStorefrontUserOrderProductVariantIntoUserOrderProductVariantType: (orderLineItemVariant: any) => UserTypes.Variant = (orderLineItemVariant) => {
  const variant: UserTypes.Variant = {
    id: orderLineItemVariant.id,
    availableForSale: orderLineItemVariant.availableForSale,
    price: getPrice(orderLineItemVariant.price),
    tags: getTags(orderLineItemVariant.product.tags, tagsDictionary),
    image: transformStorefrontImageIntoImageType(orderLineItemVariant?.image),
    title: orderLineItemVariant.title
  }

  return variant
}

// --- Generics
export const transformStorefrontImageIntoImageType: (storefrontImage: any) => ProductTypes.Image = (storefrontImage) => {
  const image: ProductTypes.Image = {
    src: storefrontImage?.transformedSrc || ''
  }
  return image
}

export const transformStorefrontPredictiveSearchProductsIntoPredictiveSearchProductsType: (storefrontPredictiveSearchProducts: any) => [] | PredictiveSearchTypes.Content[] = (storefrontPredictiveSearchProducts) => {
  if (storefrontPredictiveSearchProducts.edges.length > 0) {
    let customerType = typeof window !== 'undefined' && window.localStorage.getItem('customer_type')
    customerType = customerType ? JSON.parse(customerType) : ''

    const filteredProducts = storefrontPredictiveSearchProducts.edges.filter(({ product: searchProduct }: { product: any }) => {
      const sku = searchProduct.variants.edges[0].variant.sku || ''
      if (customerType === 'B2B') {
        return searchProduct
      } else {
        return !sku.toLowerCase().includes('b2b')
      }
    })

    return filteredProducts.map(({ product: searchProduct }: { product: any }) => {
      return transformStorefrontPredictiveSearchIntoPredictiveContentType(searchProduct)
    }) as PredictiveSearchTypes.Content[]
  }

  return []
}


export const transformStorefrontPredictiveSearchCollectionsIntoPredictiveSearchCollectionsType: (storefrontPredictiveSearchCollections: any) => [] | PredictiveSearchTypes.Content[] = (storefrontPredictiveSearchCollections) => {
  if (storefrontPredictiveSearchCollections.edges.length > 0) {
    let customerType = typeof window !== 'undefined' && window.localStorage.getItem('customer_type')
    customerType = customerType ? JSON.parse(customerType) : ''

    const filteredCollections = storefrontPredictiveSearchCollections.edges.filter(({ collection: searchCollection }: { collection: any }) => {
      if (customerType === 'B2B') {
        return searchCollection
      } else {
        return !searchCollection.handle.toLowerCase().includes('b2b')
      }
    })

    return filteredCollections.map(({ collection: searchCollection }: { collection: any }) => {
      const deepSearchCollection = {
        ...searchCollection,
        type: searchCollection.handle.toLowerCase().includes('brand') ? 'brandPage' : 'simpleCollection'
      }
      if (deepSearchCollection.handle.toLowerCase().includes('brand')) {
        deepSearchCollection.handle = deepSearchCollection.handle.replace('brand-', '')
      }
      return transformStorefrontPredictiveSearchIntoPredictiveCollectionContentType(deepSearchCollection)
    }) as PredictiveSearchTypes.Content[]
  }

  return []
}

export const transformStorefrontPredictiveSearchIntoPredictiveCollectionContentType: (storefrontPredictiveSearchContent: any) => PredictiveSearchTypes.Content = (storefrontPredictiveSearchContent) => {
  let fancyTitle: string

  switch (storefrontPredictiveSearchContent.type) {
  case 'brandPage':
    fancyTitle = `Ir a la marca ${storefrontPredictiveSearchContent.title}`
    break

  default:
    fancyTitle = `Ir a la colección de ${storefrontPredictiveSearchContent.title}`
    break
  }


  const content: PredictiveSearchTypes.Content = {
    id: storefrontPredictiveSearchContent.id,
    handle: storefrontPredictiveSearchContent.handle,
    title: fancyTitle
  }

  return content
}

export const transformStorefrontPredictiveSearchIntoPredictiveContentType: (storefrontPredictiveSearchContent: any) => PredictiveSearchTypes.Content = (storefrontPredictiveSearchContent) => {
  let fancyTitle: string

  switch (toLower(storefrontPredictiveSearchContent.productType)) {
  case 'beer':
    fancyTitle = `Cerveza ${storefrontPredictiveSearchContent.title}`
    break

  case 'beerpack':
    fancyTitle = `Beerpack ${storefrontPredictiveSearchContent.title}`
    break

  case 'merchandise':
    fancyTitle = `Accesorio ${storefrontPredictiveSearchContent.title}`
    break

  default:
    fancyTitle = storefrontPredictiveSearchContent.title
    break
  }

  const content: PredictiveSearchTypes.Content = {
    id: storefrontPredictiveSearchContent.id,
    handle: storefrontPredictiveSearchContent.handle,
    title: fancyTitle
  }

  if (
    storefrontPredictiveSearchContent.images &&
    storefrontPredictiveSearchContent.images.edges.length > 0 &&
    storefrontPredictiveSearchContent.images.edges[0].image
  ) {
    content.thumbnail = {
      src: storefrontPredictiveSearchContent.images.edges[0].image.transformedSrc
    }
  } else {
    content.thumbnail = {
      src: noPhotoAvailable
    }
  }

  return content
}

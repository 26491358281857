// Assets
import * as actionTypes from '../../actions/actionTypes'

// Types
import AppStoreTypes from '../../types/AppStoreTypes'
import FluxTypes from '../../types/FluxTypes'

const reducer: (state: AppStoreTypes.AppStoreState, action: FluxTypes.ActionObject) => AppStoreTypes.AppStoreState = (state, action) => {
  let newState = state

  switch (action.type) {

  case actionTypes.UPDATE_CART_VISIBILITY:
    newState = { ...state, isCartVisible: action.isCartVisible }
    break

  case actionTypes.UPDATE_MAIN_MENU_POPOVER_VISIBILITY:
    newState = { ...state, isMainMenuPopoverVisible: action.isMainMenuPopoverVisible }
    break

  case actionTypes.UPDATE_MODAL_DIALOG_VISIBILITY:
    newState = { ...state, isModalDialogVisible: action.isModalDialogVisible }
    break

  case actionTypes.SET_CURRENT_MODAL_DIALOG_VISIBLE:
    newState = { ...state, currentModalDialogVisible: action.currentModal }
    break

  case actionTypes.SET_CURRENT_MODAL_INFO:
    newState = {
      ...state,
      currentModalInfo: {
        title: action.title,
        message: action.message,
        buttons: action.buttons,
        child: action.child,
        hide: action.hide,
        errors: action.errors
      }
    }
    break

  case actionTypes.UPDATE_MODAL_PLP_FILTERS_VISIBILITY:
    newState = { ...state, isPLPFiltersVisible: action.isPLPFiltersVisible }
    break

  case actionTypes.SET_AGE_GATE_HAS_BEEN_ACCEPTED: {
    const ageGateHasBeenAccepted = true
    typeof window !== 'undefined' && window.localStorage.setItem('age_gate_accepted', JSON.stringify(ageGateHasBeenAccepted))
    newState = { ...state, ageGateHasBeenAccepted }
    break
  }

  case actionTypes.SET_SUBSCRIPTION_GUIDE_COMPLETED: {
    const subscriptionGuideCompleted = true
    typeof window !== 'undefined' && window.localStorage.setItem('subscription_guide_completed', JSON.stringify(subscriptionGuideCompleted))
    newState = { ...state, subscriptionGuideCompleted }
    break
  }

  case actionTypes.UPDATE_FINDER_POPOVER_VISIBILITY:
    newState = { ...state, isFinderPopoverVisible: action.isFinderPopoverVisible  }
    break

  default:

  }

  return newState
}

export default reducer

// Types
import SubscriptionTypes from '../../types/SubscriptionTypes'

const initialState: SubscriptionTypes.SubscriptionStoreState = {
  frequency: {
    label: '',
    value: '',
    isActive: false
  }
}

const mergeLocalStorageStateIntoInitialState = (state: SubscriptionTypes.SubscriptionStoreState) => {
  const newState = { ...state }
  let subscription: any = null

  try {
    subscription = typeof window !== 'undefined' && window.localStorage.getItem('subscription')
    subscription = subscription ? JSON.parse(subscription) : null
  } catch (e) {
    console.error(e)
  }

  if(subscription === null) {
    return newState
  }
  newState.frequency = subscription?.frequency
  return newState
}

const mergedInitialState = mergeLocalStorageStateIntoInitialState(initialState)

export default mergedInitialState

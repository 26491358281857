export const contactFormFields = {
  street_number: {
    getValue: 'short_name',
    isEqualsTo: 'exteriorNumber'
  },
  route: {
    getValue: 'long_name',
    isEqualsTo: 'street'
  },
  locality: {
    getValue: 'long_name',
    isEqualsTo: 'city'
  },
  administrative_area_level_1: {
    getValue: 'short_name',
    isEqualsTo: 'state'
  },
  postal_code: {
    getValue: 'short_name',
    isEqualsTo: 'zip'
  },
  sublocality_level_1: {
    getValue: 'long_name',
    isEqualsTo: 'neighborhood'
  }
}

export const options = {
  types: ['geocode'],
  componentRestrictions: { country: 'mx' }
}

export type ContactFormFieldsType = typeof contactFormFields
export type OptionsType = typeof options

// Packages
import { createContext } from 'react'

// Types
import FluxTypes from '../../types/FluxTypes'

const fluxActionsContext: FluxTypes.ActionsContext = createContext({
  actionCreators: {},
  dispatch: action => {} //eslint-disable-line
})

export default fluxActionsContext

declare namespace LandingPagesTypes {  
  export interface Image {
    src: string
    alt: string
  }

  export interface Banner {
    description: string
    imageForMobile: Image
    imageForDesktop: Image
    subtitle: string
    title: string
  }

  export interface Item {
    countryFlag?: Image
    image: Image
    title: string
    redirectTo: string | null
  }

  export interface Content {
    banner: Banner
    items: Item[]
    type: 'items_with_flag' | 'items_without_flag'
    metaDescription?: string
    metaTitle?: string
  }
}

enum SortingOptions {
  AlphabeticalAscending,
  AlphabeticalDescending
}

export {LandingPagesTypes, SortingOptions}
// Packages
import { createContext } from 'react'

// Types
import AuthTypes from '../../types/AuthTypes'
import UserTypes from '../../types/UserTypes'

export type AuthContextProps = {
    user: UserTypes.UserInfo | null
    signin: AuthTypes.Signin
    signup: AuthTypes.Signup
    signout: AuthTypes.Signout
    sendEmailToResetPassword: AuthTypes.SendEmailToResetPassword
    setCustomerAccessToken: AuthTypes.SetCustomerAccessToken
    refetchUser: () => void
    loadingUser: boolean,
    customerType: string | null
    confirmSignUp: AuthTypes.ConfirmSignUp
    resendConfirmationCode: AuthTypes.ResendConfirmationCode
    sendNewPassword: AuthTypes.SendNewPassword
    getUser: AuthTypes.GetUser
    updateUserAttributes: AuthTypes.UpdateUserAttributes
    validateExpirationTokens: AuthTypes.ValidateExpirationTokens
    handleFederatedSignup: AuthTypes.HandleFederatedSignup
    removeFederatedSignup: AuthTypes.RemoveFederatedSignup
    isNewFederatedAccount: boolean
  }

const authContext = createContext({} as AuthContextProps)

export default authContext

// Packages
import { useContext } from 'react'

// Assets
import formStoreContext from '../../contexts/formStoreContext'

// Types
import FormStoreTypes from '../../types/FormStoreTypes'

const useFormStore: () => { state: FormStoreTypes.FormStoreState } = () => {
  return useContext(formStoreContext)
}

export default useFormStore

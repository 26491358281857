// Packages
import { createContext } from 'react'

// Assets
import initialState from '../../stores/subscriptionStore/initialState'

// Types
import SubscriptionStoreTypes from '../../types/SubscriptionTypes'

const subscriptionStoreContext: SubscriptionStoreTypes.SubscriptionStoreContext = createContext({
  state: initialState
})

export default subscriptionStoreContext

// Packages
import { createContext } from 'react'

// Assets
import intialState from '../../stores/cartStore/initialState'

// Types
import CartStoreTypes from '../../types/CartStoreTypes'

const cartStoreContext: CartStoreTypes.CartStoreContext = createContext({
  state: intialState
})

export default cartStoreContext

// Packages
import _ from 'lodash'
import React from 'react'

// Assets
import * as globalActionCreators from '../../actions/actionCreators'

// Stores
// // App Store
import appStoreContext from '../../contexts/appStoreContext'
import appStoreInitialState from '../../stores/appStore/initialState'
import appStoreReducer from '../../stores/appStore/reducer'
// // Cart Store
import cartStoreContext from '../../contexts/cartStoreContext'
import cartStoreInitialState from '../../stores/cartStore/initialState'
import cartStoreReducer from '../../stores/cartStore/reducer'
// // Form Store
import formStoreContext from '../../contexts/formStoreContext'
import formStoreInitialState from '../../stores/formStore/initialState'
import formStoreReducer from '../../stores/formStore/reducer'

//Subscription Store
import subscriptionStoreContext from '../../contexts/subscriptionContext'
import subscriptionStoreInitialState from '../../stores/subscriptionStore/initialState'
import subscriptionStoreReducer from '../../stores/subscriptionStore/reducer'

// Components
import WithFluxActionsContextProvider from '../WithFluxActionsContextProvider'
import WithFluxStoreContextProvider from '../WithFluxStoreContextProvider'

// Types
import FluxTypes from '../../types/FluxTypes'

interface props {
  children: JSX.Element
}

const WithFluxContextProviders: React.FC<props> = ({ children }) => {
  const globalState: { [x: string]: FluxTypes.State } = {}
  const globalDispatchers: { name: string, dispatch: React.Dispatch<FluxTypes.ActionObject> }[] = []

  // This function groups all the reducers' states under a single object that can be easily passed around
  const addToGlobalState = (state: FluxTypes.State, name: string) => {
    globalState[name] = state
  }

  // This function groups all the reducers' dispatchers under a single array to have access to all of them at the same time
  const addToGlobalDispatchers = (dispatch: React.Dispatch<FluxTypes.ActionObject>, name: string) => {
    const index = _.findIndex(globalDispatchers, ['name', name])
    if (index !== -1) {
      globalDispatchers.splice(index, 1, { name, dispatch })
    } else {
      globalDispatchers.push({ name, dispatch })
    }
  }

  // This function receives an action and dispatches it to all the stores
  const globalDispatch = (action: FluxTypes.Action) => {
    // This condition basically does the same thing as the Redux Thunk middleware in Redux
    if (typeof action === 'function') {
      const resultAction = action(globalDispatch, globalState)
      if (typeof resultAction === 'object') {
        globalDispatchers.forEach(({ dispatch }) => dispatch(resultAction))
      }
    } else {
      globalDispatchers.forEach(({ dispatch }) => dispatch(action))
    }
  }

  return (
    <WithFluxActionsContextProvider
      globalActionCreators={globalActionCreators}
      globalDispatch={globalDispatch}
    >
      <WithFluxStoreContextProvider
        addToGlobalDispatchers={addToGlobalDispatchers}
        addToGlobalState={addToGlobalState}
        context={appStoreContext}
        initialState={appStoreInitialState}
        name='appStore'
        reducer={appStoreReducer}
      >
        <WithFluxStoreContextProvider
          addToGlobalDispatchers={addToGlobalDispatchers}
          addToGlobalState={addToGlobalState}
          context={cartStoreContext}
          initialState={cartStoreInitialState}
          name='cartStore'
          reducer={cartStoreReducer
          }>
          <WithFluxStoreContextProvider
            addToGlobalDispatchers={addToGlobalDispatchers}
            addToGlobalState={addToGlobalState}
            context={formStoreContext}
            initialState={formStoreInitialState}
            name='formStore'
            reducer={formStoreReducer}
          >
            <WithFluxStoreContextProvider
              addToGlobalDispatchers={addToGlobalDispatchers}
              addToGlobalState={addToGlobalState}
              context={subscriptionStoreContext}
              initialState={subscriptionStoreInitialState}
              name='subscriptionStore'
              reducer={subscriptionStoreReducer}
            >
              {children}
            </WithFluxStoreContextProvider>
          </WithFluxStoreContextProvider>
        </WithFluxStoreContextProvider>
      </WithFluxStoreContextProvider>
    </WithFluxActionsContextProvider>
  )
}

export default WithFluxContextProviders

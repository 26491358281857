// Types
import TagsDictionaryTypes from '../../../types/TagsDictionaryTypes'

const tagsDictionary: TagsDictionaryTypes.TagsObject = {
  volumen: 'Volumen',
  pais: 'País',
  estilo: 'Estilo',
  subestilo: 'Sub-estilo',
  sabor1: 'Sabor1',
  sabor2: 'Sabor2',
  color: 'Color',
  abv: '% Alcohol',
  cuerpo: 'Cuerpo',
  ibu: 'IBU',
  'brand-page': 'vendorHandle',
  extraItem: 'extraItem',
  minusItem: 'minusItem',
  maridaje: 'maridaje',
  'top-ten-position': 'topTenPosition',
  'onlyLeftItems': 'onlyLeftItems'
}

export default tagsDictionary

export type TagsDictionaryType = typeof tagsDictionary

export const ADD_VARIANT_TO_CART = 'ADD_VARIANT_TO_CART'
export const REMOVE_VARIANT_FROM_CART = 'REMOVE_VARIANT_FROM_CART'
export const UPDATE_CART_VISIBILITY = 'UPDATE_CART_VISIBILITY'
export const UPDATE_MAIN_MENU_POPOVER_VISIBILITY = 'UPDATE_MAIN_MENU_POPOVER_VISIBILITY'
export const UPDATE_MODAL_DIALOG_VISIBILITY = 'UPDATE_MODAL_DIALOG_VISIBILITY'
export const SET_CURRENT_MODAL_DIALOG_VISIBLE = 'SET_CURRENT_MODAL_DIALOG_VISIBLE'
export const SET_CURRENT_MODAL_INFO = 'SET_CURRENT_MODAL_INFO'
export const UPDATE_MODAL_PLP_FILTERS_VISIBILITY = 'UPDATE_MODAL_PLP_FILTERS_VISIBILITY'
export const SET_AGE_GATE_HAS_BEEN_ACCEPTED = 'SET_AGE_GATE_HAS_BEEN_ACCEPTED'
export const UPDATE_FINDER_POPOVER_VISIBILITY = 'UPDATE_FINDER_POPOVER_VISIBILITY'
export const SET_SUBSCRIPTION_GUIDE_COMPLETED = 'SET_SUBSCRIPTION_GUIDE_COMPLETED'
// Login
export const SET_LOGIN_INITIAL_STATE = 'SET_LOGIN_INITIAL_STATE'
export const SET_LOGIN_EMAIL = 'SET_LOGIN_EMAIL'
export const SET_LOGIN_EMAIL_ERRORS = 'SET_LOGIN_EMAIL_ERRORS'
export const SET_LOGIN_PASSWORD = 'SET_LOGIN_PASSWORD'
export const SET_LOGIN_PASSWORD_ERRORS = 'SET_LOGIN_PASSWORD_ERRORS'
export const UPDATE_LOGIN_KEEP_SESSION = 'UPDATE_LOGIN_KEEP_SESSION'
export const UPDATE_LOGIN_ACCEPT_POLICY = 'UPDATE_LOGIN_ACCEPT_POLICY'
export const UPDATE_LOGIN_ACCEPT_POLICY_ERRORS = 'UPDATE_LOGIN_ACCEPT_POLICY_ERRORS'
// Recover password
export const SET_RECOVER_PASSWORD_EMAIL = 'SET_RECOVER_PASSWORD_EMAIL'
export const SET_RECOVER_PASSWORD_EMAIL_ERROS = 'SET_RECOVER_PASSWORD_EMAIL_ERRORS'
// Create account
export const SET_CREATE_ACCOUNT_INITIAL_STATE = 'SET_CREATE_ACCOUNT_INITIAL_STATE'
export const SET_CREATE_ACCOUNT_NAME = 'SET_CREATE_ACCOUNT_NAME'
export const SET_CREATE_ACCOUNT_NAME_ERRORS = 'SET_CREATE_ACCOUNT_NAME_ERRORS'
export const SET_CREATE_ACCOUNT_LAST_NAME = 'SET_CREATE_ACCOUNT_LAST_NAME'
export const SET_CREATE_ACCOUNT_LAST_NAME_ERRORS = 'SET_CREATE_ACCOUNT_LAST_NAME_ERRORS'
export const SET_CREATE_ACCOUNT_EMAIL = 'SET_CREATE_ACCOUNT_EMAIL'
export const SET_CREATE_ACCOUNT_EMAIL_ERRORS = 'SET_CREATE_ACCOUNT_EMAIL_ERRORS'
export const SET_CREATE_ACCOUNT_PASSWORD = 'SET_CREATE_ACCOUNT_PASSWORD'
export const SET_CREATE_ACCOUNT_PASSWORD_ERRORS = 'SET_CREATE_ACCOUNT_PASSWORD_ERRORS'
export const SET_CREATE_ACCOUNT_CONFIRM_PASSWORD = 'SET_CREATE_ACCOUNT_CONFIRM_PASSWORD'
export const SET_CREATE_ACCOUNT_CONFIRM_PASSWORD_ERRORS = 'SET_CREATE_ACCOUNT_CONFIRM_PASSWORD_ERRORS'
export const SET_CREATE_ACCOUNT_BIRTHDAY = 'SET_CREATE_ACCOUNT_BIRTHDAY'
export const SET_CREATE_ACCOUNT_BIRTHDAY_ERRORS = 'SET_CREATE_ACCOUNT_BIRTHDAY_ERRORS'
export const UPDATE_CREATE_ACCOUNT_SUBSCRIBE_NEWSLETTER = 'UPDATE_CREATE_ACCOUNT_SUBSCRIBE_NEWSLETTER'
export const UPDATE_CREATE_ACCOUNT_ACCEPT_TERMS = 'UPDATE_CREATE_ACCOUNT_ACCEPT_TERMS'
export const UPDATE_CREATE_ACCOUNT_ACCEPT_TERMS_ERRORS = 'UPDATE_CREATE_ACCOUNT_ACCEPT_TERMS_ERRORS'
export const SET_CREATE_ACCOUNT_BUSINESS_RFC = 'SET_CREATE_ACCOUNT_BUSINESS_RFC'
export const SET_CREATE_ACCOUNT_BUSINESS_RFC_ERRORS = 'SET_CREATE_ACCOUNT_BUSINESS_RFC_ERRORS'
export const SET_CREATE_ACCOUNT_BUSINESS_NAME = 'SET_CREATE_ACCOUNT_BUSINESS_NAME'
export const SET_CREATE_ACCOUNT_BUSINESS_NAME_ERRORS = 'SET_CREATE_ACCOUNT_BUSINESS_NAME_ERRORS'
export const SET_CREATE_ACCOUNT_BUSINESS_BILLING_ADDRESS = 'SET_CREATE_ACCOUNT_BUSINESS_BILLING_ADDRESS'
export const SET_CREATE_ACCOUNT_BUSINESS_BILLING_ADDRESS_ERRORS = 'SET_CREATE_ACCOUNT_BUSINESS_BILLING_ADDRESS_ERRORS'
export const SET_CREATE_ACCOUNT_BUSINESS_SAP = 'SET_CREATE_ACCOUNT_BUSINESS_SAP'
export const SET_CREATE_ACCOUNT_BUSINESS_SAP_ERRORS = 'SET_CREATE_ACCOUNT_BUSINESS_SAP_ERRORS'
// Customer info
export const SET_CUSTOMER_INFO_EMAIL = 'SET_CUSTOMER_INFO_EMAIL'
export const SET_CUSTOMER_INFO_EMAIL_ERRORS = 'SET_CUSTOMER_INFO_EMAIL_ERRORS '
export const SET_CUSTOMER_INFO_BIRTHDAY = 'SET_CUSTOMER_INFO_BIRTHDAY'
export const SET_CUSTOMER_INFO_BIRTHDAY_ERRORS = 'SET_CUSTOMER_INFO_BIRTHDAY_ERRORS'
export const SET_CUSTOMER_INFO_NAME = 'SET_CUSTOMER_INFO_NAME'
export const SET_CUSTOMER_INFO_NAME_ERRORS = 'SET_CUSTOMER_INFO_NAME_ERRORS'
export const SET_CUSTOMER_INFO_LAST_NAME = 'SET_CUSTOMER_INFO_LAST_NAME'
export const SET_CUSTOMER_INFO_LAST_NAME_ERRORS = 'SET_CUSTOMER_INFO_LAST_NAME_ERRORS'
export const SET_CUSTOMER_INFO_EMAIL_PLACEHOLDER = 'SET_CUSTOMER_INFO_EMAIL_PLACEHOLDER'
export const SET_CUSTOMER_INFO_NAME_PLACEHOLDER = 'SET_CUSTOMER_INFO_NAME_PLACEHOLDER'
export const SET_CUSTOMER_INFO_LAST_NAME_PLACEHOLDER = 'SET_CUSTOMER_INFO_LAST_NAME_PLACEHOLDER'
export const SET_CUSTOMER_INFO_BUSINESS_RFC = 'SET_CUSTOMER_INFO_BUSINESS_RFC'
export const SET_CUSTOMER_INFO_BUSINESS_RFC_ERRORS = 'SET_CUSTOMER_INFO_BUSINESS_RFC_ERRORS'
export const SET_CUSTOMER_INFO_BUSINESS_NAME = 'SET_CUSTOMER_INFO_BUSINESS_NAME'
export const SET_CUSTOMER_INFO_BUSINESS_NAME_ERRORS = 'SET_CUSTOMER_INFO_BUSINESS_NAME_ERRORS'
export const SET_CUSTOMER_INFO_BUSINESS_BILLING_ADDRESS = 'SET_CUSTOMER_INFO_BUSINESS_BILLING_ADDRESS'
export const SET_CUSTOMER_INFO_BUSINESS_BILLING_ADDRESS_ERRORS = 'SET_CUSTOMER_INFO_BUSINESS_BILLING_ADDRESS_ERRORS'
export const SET_CUSTOMER_INFO_BUSINESS_SAP = 'SET_CUSTOMER_INFO_BUSINESS_SAP'
export const SET_CUSTOMER_INFO_BUSINESS_SAP_ERRORS = 'SET_CUSTOMER_INFO_BUSINESS_SAP_ERRORS'
export const SET_CUSTOMER_INFO_CHANGE_PASSWORD_NEW_PASSWORD = 'SET_CUSTOMER_INFO_CHANGE_PASSWORD_NEW_PASSWORD'
export const SET_CUSTOMER_INFO_CHANGE_PASSWORD_NEW_PASSWORD_ERRORS = 'SET_CUSTOMER_INFO_CHANGE_PASSWORD_NEW_PASSWORD_ERRORS'
export const SET_CUSTOMER_INFO_CHANGE_PASSWORD_CONFIRM_NEW_PASSWORD = 'SET_CUSTOMER_INFO_CHANGE_PASSWORD_CONFIRM_NEW_PASSWORD'
export const SET_CUSTOMER_INFO_CHANGE_PASSWORD_CONFIRM_NEW_PASSWORD_ERRORS = 'SET_CUSTOMER_INFO_CHANGE_PASSWORD_CONFIRM_NEW_PASSWORD_ERRORS'
// Shipping address
export const SET_SHIPPING_ADDRESS_NAME = 'SET_SHIPPING_ADDRESS_NAME'
export const SET_SHIPPING_ADDRESS_NAME_ERRORS = 'SET_SHIPPING_ADDRESS_NAME_ERRORS'
export const SET_SHIPPING_ADDRESS_LAST_NAME = 'SET_SHIPPING_ADDRESS_LAST_NAME'
export const SET_SHIPPING_ADDRESS_LAST_NAME_ERRORS = 'SET_SHIPPING_ADDRESS_LAST_NAME_ERRORS'
export const SET_SHIPPING_ADDRESS_AUTOCOMPLETE = 'SET_SHIPPING_ADDRESS_AUTOCOMPLETE'
export const SET_SHIPPING_ADDRESS_AUTOCOMPLETE_ERRORS = 'SET_SHIPPING_ADDRESS_AUTOCOMPLETE_ERRORS'
export const SET_SHIPPING_ADDRESS_STREET = 'SET_SHIPPING_ADDRESS_STREET'
export const SET_SHIPPING_ADDRESS_STREET_ERRORS = 'SET_SHIPPING_ADDRESS_STREET_ERRORS'
export const SET_SHIPPING_ADDRESS_EXTERIOR_NUMBER = 'SET_SHIPPING_ADDRESS_EXTERIOR_NUMBER'
export const SET_SHIPPING_ADDRESS_EXTERIOR_NUMBER_ERRORS = 'SET_SHIPPING_ADDRESS_EXTERIOR_NUMBER_ERRORS'
export const SET_SHIPPING_ADDRESS_INTERIOR_NUMBER = 'SET_SHIPPING_ADDRESS_INTERIOR_NUMBER'
export const SET_SHIPPING_ADDRESS_INTERIOR_NUMBER_ERRORS = 'SET_SHIPPING_ADDRESS_INTERIOR_NUMBER_ERRORS'
export const SET_SHIPPING_ADDRESS_ZIP = 'SET_SHIPPING_ADDRESS_ZIP'
export const SET_SHIPPING_ADDRESS_ZIP_ERRORS = 'SET_SHIPPING_ADDRESS_ZIP_ERROR'
export const SET_SHIPPING_ADDRESS_NEIGHBORHOOD = 'SET_SHIPPING_ADDRESS_NEIGHBORHOOD'
export const SET_SHIPPING_ADDRESS_NEIGHBORHOOD_ERRORS = 'SET_SHIPPING_ADDRESS_NEIGHBORHOOD_ERRORS'
export const SET_SHIPPING_ADDRESS_STATE = 'SET_SHIPPING_ADDRESS_STATE'
export const SET_SHIPPING_ADDRESS_STATE_ERRORS = 'SET_SHIPPING_ADDRESS_STATE_ERRORS'
export const SET_SHIPPING_ADDRESS_CITY = 'SET_SHIPPING_ADDRESS_CITY'
export const SET_SHIPPING_ADDRESS_CITY_ERRORS = 'SET_SHIPPING_ADDRESS_CITY_ERRORS'
export const SET_SHIPPING_ADDRESS_MUNICIPALITY = 'SET_SHIPPING_ADDRESS_MUNICIPALITY'
export const SET_SHIPPING_ADDRESS_MUNICIPALITY_ERRORS = 'SET_SHIPPING_ADDRESS_MUNICIPALITY_ERRORS'
export const SET_SHIPPING_ADDRESS_REFERENCES = 'SET_SHIPPING_ADDRESS_REFERENCES'
export const SET_SHIPPING_ADDRESS_REFERENCES_ERRORS = 'SET_SHIPPING_ADDRESS_REFERENCES_ERRORS'
export const SET_SHIPPING_ADDRESS_PHONE = 'SET_SHIPPING_ADDRESS_PHONE'
export const SET_SHIPPING_ADDRESS_PHONE_ERRORS = 'SET_SHIPPING_ADDRESS_PHONE_ERRORS'
export const UPDATE_ADDRESS_MAINADDRESS = 'UPDATE_ADDRESS_MAINADDRESS'
//Credit cards
export const SET_CARD_NAME = 'SET_CARD_NAME'
export const SET_CARD_NAME_ERRORS = 'SET_CARD_NAME_ERRORS'
export const SET_CARD_NUMBER = 'SET_CARD_NUMBER'
export const SET_CARD_NUMBER_ERRORS = 'SET_CARD_NUMBER_ERRORS'
export const SET_CARD_LIST_MONTHS = 'SET_CARD_LIST_MONTHS'
export const SET_CARD_EXPIRY_MONTH = 'SET_CARD_EXPIRY_MONTH'
export const SET_CARD_EXPIRY_MONTH_ERRORS = 'SET_CARD_EXPIRY_MONTH_ERRORS'
export const SET_CARD_LIST_YEARS = 'SET_CARD_LIST_YEARS'
export const SET_CARD_EXPIRY_YEAR = 'SET_CARD_EXPIRY_YEAR'
export const SET_CARD_EXPIRY_YEAR_ERRORS = 'SET_CARD_EXPIRY_YEAR_ERRORS'
export const SET_CARD_CVV = 'SET_CARD_CVV'
export const SET_CARD_CVV_ERRORS = 'SET_CARD_CVV_ERRORS'
// subscription
export const SET_SUBSCRIPTION_FREQUENCY = 'SET_SUBSCRIPTION_FREQUENCY'

// Packages
import { useContext } from 'react'

// Assets
import fluxActionsContext from '../../contexts/fluxActionsContext'

// Types
import FluxTypes from '../../types/FluxTypes'

interface UseFluxActionsReturnProps {
  actionCreators: FluxTypes.ActionCreators
  dispatch: FluxTypes.GlobalDispatch
}

const useFluxActions: () => UseFluxActionsReturnProps = () => {
  return useContext(fluxActionsContext)
}

export default useFluxActions

// Types
import FormTypes from '../../types/FormStoreTypes'

const initialState: FormTypes.FormStoreState = {
  login:  {
    email: {
      name: 'email',
      label: 'Correo electrónico *',
      placeholder: 'Tu correo electrónico',
      value: '',
      errors: []
    },
    password: {
      name: 'password',
      label: 'Contraseña *',
      placeholder: '*******',
      value: '',
      errors: []
    },
    keepSession: {
      name: 'keepSession',
      label: 'Recordarme',
      placeholder: '',
      value: true,
      errors: []
    },
    acceptPolicyNConditions: {
      name: 'acceptPolicyNConditions',
      label: 'Acepto los Términos y condiciones - Política de privacidad',
      placeholder: '',
      value: false,
      errors: []
    },
    recoverPassword: {
      email: {
        name: 'email',
        label: 'Correo electrónico *',
        placeholder: 'Tu correo electrónico',
        value: '',
        errors: []
      }
    }
  },
  createAccount: {
    name: {
      name: 'name',
      label: 'Nombre *',
      placeholder: 'Tu nombre',
      value: '',
      errors: []
    },
    lastName: {
      name: 'lastName',
      label: 'Apellido *',
      placeholder: 'Tu apellido',
      value: '',
      errors: []
    },
    email: {
      name: 'email',
      label: 'Correo electrónico *',
      placeholder: 'Tu correo electrónico',
      value: '',
      errors: []
    },
    birthday:{
      name: 'birthday',
      label: 'Fecha de cumpleaños *',
      placeholder: 'DD / MM / AAAA',
      value: '',
      errors: []
    },
    password: {
      name: 'password',
      label: 'Contraseña *',
      placeholder: '*******',
      value: '',
      errors: []
    },
    confirmPassword: {
      name: 'confirmPassword',
      label: 'Confirmar contraseña *',
      placeholder: '*******',
      value: '',
      errors: []
    },
    subscribeNewsletter: {
      name: 'subscribeNewsletter',
      label: 'Quiero inscribirme al newsletter',
      placeholder: '',
      value: false,
      errors: []
    },
    acceptTerms: {
      name: 'acceptTerms',
      label: 'Acepto los términos y condiciones, políticas de privacidad y términos de promociones de Beerhouse',
      placeholder: '',
      value: false,
      errors: []
    },
    business: {
      rfc: {
        name: 'businessRfc',
        label: 'RFC *',
        placeholder: 'Número de 16 digitos',
        value: '',
        errors: []
      },
      businessName: {
        name: 'businessName',
        label: 'Razón social *',
        placeholder: 'El nombre de la empresa o compañía',
        value: '',
        errors: []
      },
      billingAddress: {
        name: 'businessBillingAddress',
        label: 'Dirección de facturación *',
        placeholder: 'Los datos fiscales para realizar la factura',
        value: '',
        errors: []
      },
      sap: {
        name: 'businessSap',
        label: 'Cliente SAP',
        placeholder: 'Número de cliente en el sistema (Opcional)',
        value: '',
        errors: []
      },
    }
  },
  customerInfo: {
    email: {
      name: 'email',
      label: 'Correo electrónico *',
      placeholder: 'Tu correo electrónico',
      value: '',
      errors: []
    },
    name: {
      name: 'name',
      label: 'Nombre *',
      placeholder: 'Tu nombre',
      value: '',
      errors: []
    },
    lastName: {
      name: 'lastName',
      label: 'Apellido *',
      placeholder: 'Tu apellido *',
      value: '',
      errors: []
    },
    birthday: {
      name: 'birthday',
      label: 'Fecha de cumpleaños *',
      placeholder: 'DD / MM / AAAA',
      value: '',
      errors: []
    },
    business: {
      rfc: {
        name: 'businessRfc',
        label: 'RFC *',
        placeholder: 'Número de 16 digitos',
        value: '',
        errors: []
      },
      businessName: {
        name: 'businessName',
        label: 'Razón social *',
        placeholder: 'El nombre de la empresa o compañía',
        value: '',
        errors: []
      },
      billingAddress: {
        name: 'businessBillingAddress',
        label: 'Dirección de facturación *',
        placeholder: 'Los datos fiscales para realizar la factura',
        value: '',
        errors: []
      },
      sap: {
        name: 'businessSap',
        label: 'Cliente SAP',
        placeholder: 'Número de cliente en el sistema (Opcional)',
        value: '',
        errors: []
      },
    },
    changePassword: {
      newPassword: {
        name: 'newPassword',
        label: 'Contraseña',
        placeholder: '*******',
        value: '',
        errors: []
      },
      confirmNewPassword: {
        name: 'confirmNewPassword',
        label: 'Repetir contraseña',
        placeholder: '*******',
        value: '',
        errors: []
      }
    }
  },
  shipping: {
    address: {
      name: {
        name: 'name',
        label: 'Nombre *',
        placeholder: 'El nombre de la persona que va a recibir el paquete',
        value: '',
        errors: []
      },
      lastName: {
        name: 'lastName',
        label: 'Apellido *',
        placeholder: 'El apellido de la persona que va a recibir el paquete',
        value: '',
        errors: []
      },
      autocomplete: {
        name: 'autocomplete',
        label: 'Dirección',
        placeholder: 'Busca aquí la dirección de envío',
        value: '',
        errors: []
      },
      street: {
        name: 'street',
        label: 'Calle *',
        placeholder: 'Nombre de la calle',
        value: '',
        errors: []
      },
      exteriorNumber: {
        name: 'exteriorNumber',
        label: 'Número exterior *',
        placeholder: '#',
        value: '',
        errors: []
      },
      interiorNumber: {
        name: 'interiorNumber',
        label: 'Número interior',
        placeholder: 'Departamento, piso, etc',
        value: '',
        errors: []
      },
      zip: {
        name: 'zip',
        label: 'Código Postal *',
        placeholder: '5 digitos',
        value: '',
        errors: []
      },
      neighborhood: {
        name: 'neighborhood',
        label: 'Colonia *',
        placeholder: 'El nombre de la colonia de la dirección de envío',
        value: '',
        errors: []
      },
      state: {
        name: 'state',
        label: 'Estado *',
        placeholder: 'Selecciona un estado',
        value: '',
        errors: []
      },
      country: {
        name: 'country',
        label: 'País',
        placeholder: '',
        value: 'México',
        errors: []
      },
      city: {
        name: 'city',
        label: 'Ciudad *',
        placeholder: 'Selecciona una ciudad',
        value: '',
        errors: []
      },
      municipality: {
        name: 'municipality',
        label: 'Alcaldía / Municipio *',
        placeholder: 'Escribe la alcaldía o municipio',
        value: '',
        errors: []
      },
      references: {
        name: 'references',
        label: 'Referencias',
        placeholder: 'Entre que calles, referencias adicionales, etc',
        value: '',
        errors: []
      },
      phone: {
        name: 'phone',
        label: 'Teléfono *',
        placeholder: 'Teléfono de referencia',
        value: '',
        errors: []
      },
      mainAddress: {
        name: 'mainAddress',
        label: 'Definir esta dirección como principal',
        placeholder: '',
        value: false,
        errors: []
      }
    }
  },
  payment: {
    card: {
      name: {
        name: 'name',
        label: 'Nombre completo  *',
        placeholder: 'Ingresa tu(s) nombre(s) y apellido(s)',
        value: '',
        errors: []
      },
      number: {
        name: 'number',
        label: 'Número de la tarjeta *',
        placeholder: '0000 - 0000 - 0000 - 0000',
        value: '',
        errors: []
      },
      expiryMonth: {
        name: 'expiryMonth',
        label: 'Mes de expiración *',
        options: [],
        value: '',
        errors: []
      },
      expiryYear: {
        name: 'expiryYear',
        label: 'Año de expiración *',
        options: [],
        value: '',
        errors: []
      },
      cvv: {
        name: 'cvv',
        label: 'CVV *',
        placeholder: '000',
        value: '',
        errors: []
      }
    }
  },
}

export default initialState

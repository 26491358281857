// Types
import CartStoreTypes from '../../types/CartStoreTypes'

const initialState: CartStoreTypes.CartStoreState = {
  items: []
}

const mergeLocalStorageStateIntoInitialState = (state: CartStoreTypes.CartStoreState) => {
  const newState = { ...state }

  let cart: any = null

  try {
    cart = typeof window !== 'undefined' && window.localStorage.getItem('cart')
    cart = cart ? JSON.parse(cart) : null
  } catch (e) {
    console.error(e)
  }

  if(cart === null || Array.isArray(cart?.items) === false) {
    return newState
  }

  cart.items.forEach((item: CartStoreTypes.Item) => {
    newState.items.push(item)
  })

  return newState
}

const mergedInitialState = mergeLocalStorageStateIntoInitialState(initialState)

export default mergedInitialState

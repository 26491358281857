// Packages
import React from 'react'
import fetch from 'cross-fetch'
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext, ContextSetter } from '@apollo/client/link/context'

// Assets
import config from '../../data/config'

// @ts-ignore
global.Headers = fetch.Headers

const cache = new InMemoryCache({}).restore({})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const setContextCallback: ContextSetter = (_, { headers, ...rest }) => {
  return {
    ...rest,
    headers: {
      ...headers,
      'X-Shopify-Storefront-Access-Token': config.shopify.accessToken
    }
  }
}

const authLink = setContext(setContextCallback)

const shopifyStorefrontHttpLink = new HttpLink({
  uri: `https://${config.shopify.subdomain}.myshopify.com/api/${config.shopify.apiVersion}/graphql.json`,
  fetch
})

const setCheckoutContextCallback: ContextSetter = (_, { headers, ...rest }) => {
  return {
    ...rest,
    headers: {
      ...headers,
      'X-Shopify-Storefront-Access-Token': config.shopifyCheckout.accessToken
    }
  }
}

const checkoutAuthLink = setContext(setCheckoutContextCallback)

const shopifyCheckoutHttpLink = new HttpLink({
  uri: `https://${config.shopifyCheckout.subdomain}.myshopify.com/api/${config.shopify.apiVersion}/graphql.json`,
  fetch
})

const bhGraphQLServices = new HttpLink({
  uri: `https://${config.bhGraphQLServices.baseUrl}/graphql`,
  fetch
})

const subscriptionGraphQLServices = new HttpLink({
  uri: `https://${config.subscriptionGraphQLServices.baseUrl}/graphql`,
  fetch
})

const subsLinks = ApolloLink.split(
  (operation) => operation.getContext().clientName === 'api-subscription-services',
  subscriptionGraphQLServices,
  authLink.concat(shopifyStorefrontHttpLink)
)

const otherLinks = ApolloLink.split(
  (operation) => operation.getContext().clientName === 'api-gateway-services',
  bhGraphQLServices,
  subsLinks
)

const apolloClient = new ApolloClient({
  cache,
  link: ApolloLink.from([
    errorLink,
    // @ts-ignore
    ApolloLink.split(
      (operation) => operation.getContext().clientName === 'checkout',
      checkoutAuthLink.concat(shopifyCheckoutHttpLink),
      otherLinks
    )
  ])
})

interface props {
  children: JSX.Element
}

const WithApolloProvider: React.FC<props> = ({ children }) => (
  // @ts-ignore
  <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
)

export default WithApolloProvider

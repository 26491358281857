module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Beerhouse","short_name":"Beerhouse","start_url":"/","background_color":"#fff","theme_color":"#00447c","display":"standalone","icon":"src/data/assets/img/favicon/favicon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e0935c500b5f48b109fd89e3af76f103"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Inicio","exclude":["/dev-404-page/","/404/","/404.html","/","/login/","/login/recuperar-cuenta/","/mi-cuenta/cambiar-contrasena/","/qr/caja-de-envio/"],"crumbLabelUpdates":[{"pathname":"/productos","crumbLabel":"Producto"},{"pathname":"/mi-cuenta","crumbLabel":"Mi perfil"},{"pathname":"/productos/por-estilo","crumbLabel":"Por estilo"},{"pathname":"/productos/por-marca","crumbLabel":"Por marca"},{"pathname":"/productos/por-pais","crumbLabel":"Por país"},{"pathname":"/colecciones","crumbLabel":"Colección"},{"pathname":"/colecciones/por-marca","crumbLabel":"Por marca"},{"pathname":"/entradas","crumbLabel":"Entradas"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K27H4N","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

// Packages
import { useEffect, useState } from 'react'
import { useLazyQuery, ApolloQueryResult } from '@apollo/client'
import moment from 'moment'
import { navigate } from 'gatsby'
import {
  signIn,
  signUp,
  confirmSignUp,
  signOut,
  resendSignUpCode,
  confirmResetPassword,
  resetPassword,
  fetchAuthSession,
  getCurrentUser,
  updateUserAttributes,
  fetchUserAttributes,
  type AuthTokens
} from 'aws-amplify/auth'
import { Hub } from 'aws-amplify/utils'

// Assets
import useData from '../useData'
import { GET_CUSTOMER_QUERY } from '../../graphql/queries'
import useLocalStorage from '../useLocalStorage'
import { transformStorefrontUserIntoUserType } from '../../utilities/storefrontApi'
import {
  handlerErrorCognito,
  formattedStoreFrontTokenCognito,
  formattedCheckoutTokenCognito,
  formattedCognitoCustomAttributes,
  addMinutes,
} from '../../utilities/general'
import useFluxActions from '../useFluxActions'
import useFormStore from '../../hooks/useFormStore'

// Types
import AuthTypes from '../../types/AuthTypes'
import UserTypes from '../../types/UserTypes'

const GENERIC_ERROR = 'Lo sentimos, hubo un error. Inténtelo más tarde.'
const ACCOUNT_NEEDS_CONFIRM = 'CONFIRM_SIGN_UP'
const RESET_REQUIRED = 'RESET_PASSWORD'
const ACTUAL_TERMS_VERSION = '2022-11-15'

interface UseProvideAuthReturnProps {
  user: UserTypes.UserInfo | null
  signin: AuthTypes.Signin
  signup: AuthTypes.Signup
  signout: AuthTypes.Signout
  sendEmailToResetPassword: AuthTypes.SendEmailToResetPassword
  setCustomerAccessToken: (value: {
    accessToken: string
    expiresAt: string
  }) => void
  refetchUser: (variables?: { accessToken: string } | undefined) => Promise<ApolloQueryResult<any>>
  loadingUser: boolean,
  customerType: string
  confirmSignUp: AuthTypes.ConfirmSignUp
  resendConfirmationCode: AuthTypes.ResendConfirmationCode
  sendNewPassword: AuthTypes.SendNewPassword
  getUser: AuthTypes.GetUser
  updateUserAttributes: AuthTypes.UpdateUserAttributes
  validateExpirationTokens: AuthTypes.ValidateExpirationTokens
  handleFederatedSignup: AuthTypes.HandleFederatedSignup
  removeFederatedSignup: AuthTypes.RemoveFederatedSignup
  isNewFederatedAccount: boolean
}

const useProvideAuth: () => UseProvideAuthReturnProps = () => {
  const [user, setUser] = useState<UserTypes.UserInfo | null>(null)
  const { config } = useData()
  const {
    actionCreators,
    dispatch
  } = useFluxActions()

  const {
    state: {
      login: loginFormState,
      createAccount: createAccountFormState
    }
  } = useFormStore()

  const [customerAccessToken, setCustomerAccessToken] = useLocalStorage('customer_access_token')
  const [, setCheckoutCustomerAccessToken] = useLocalStorage('checkout_customer_access_token')
  const [customerType, setCustomerType] = useLocalStorage('customer_type')
  const [keepSessionOpened, setKeepSessionOpened] = useLocalStorage('keep_session_opened')
  const [, setEmailToConfirm] = useLocalStorage('email_to_confirm')
  const [nowDateUTC, setNowDateUTC] = useState(moment.utc().format())
  const [isNewFederatedAccount, setIsNewFederatedAccount] = useLocalStorage('is_new_federated_account')

  const [refetch, { loading }] = useLazyQuery(GET_CUSTOMER_QUERY)

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event } }: { payload: { event: string } }) => {
      switch (event) {
      case 'signIn':
      case 'cognitoHostedUI':
      case 'signInWithRedirect':
      case 'tokenRefresh':
        getUser()
        break
      case 'signIn_failure':
      case 'signedOut':
        clearTokens()
        break
      case 'signInWithRedirect_failure':
      case 'cognitoHostedUI_failure':
      case 'tokenRefresh_failure':
        if (window?.location?.pathname.includes('/mi-cuenta')) {
          const date = addMinutes(new Date(), 10)
          const alertSignOutDate = window.localStorage.getItem('alert_signOut_date')
          if (!alertSignOutDate || new Date() > new Date(alertSignOutDate)) {
            alert('Se ha cerrado la sesión debido a que ha expirado. Por favor, inicia sesión nuevamente.')
            window.localStorage.setItem('alert_signOut_date', date.toISOString())
          }
          handleSignOut()
        } else {
          clearTokens()
        }
        break
      }
    })

    getUser()
    return unsubscribe
  }, [])

  const checkTermsVersion = async (cognitoAttributes: Record<string, never>) => {
    const stopTermsRefreshing = window?.location?.pathname
      ? window.location.pathname.includes('login/aceptar-tyc')
      : false
    if (
      stopTermsRefreshing ||
      cognitoAttributes.terms_version === ACTUAL_TERMS_VERSION ||
      loginFormState.acceptPolicyNConditions.value ||
      createAccountFormState.acceptTerms.value
    ) return
    if (
      Object.keys(cognitoAttributes).length === 0 ||
      !cognitoAttributes.terms_version ||
      cognitoAttributes.terms_version !== ACTUAL_TERMS_VERSION
    ) navigate('/login/aceptar-tyc')
  }

  const getDataUser: (
    data: UserTypes.UserInfo,
    cognito: AuthTokens
  ) => void = async (
    data,
    cognito
  ) => {
    if (data) {
      const customer = await transformStorefrontUserIntoUserType(data.customer)
      if (cognito) {
        customer.cognito = cognito
      }
      const userAttributesResponse = await fetchUserAttributes()
      customer.userAttributes = formattedCognitoCustomAttributes(userAttributesResponse)

      if (customer?.info && customer?.userAttributes?.is_business) {
        customer.info.businessData = {
          billingAddress: customer.userAttributes.business_direccion,
          businessName: customer.userAttributes.business_razon_social,
          rfc: customer.userAttributes.business_rfc,
          sap: customer.userAttributes.business_codigo_preventa
        }
      }

      if (customer?.info && customer?.userAttributes) {
        customer.info.birthday = customer.userAttributes.birthday || ''
      }

      if (customer && customer.info && customer.info.businessData) {
        setCustomerType('B2B')
      } else {
        setCustomerType('B2C')
      }

      await checkTermsVersion(customer.userAttributes)
      setUser({ ...customer })
    } else {
      setUser(null)
    }
  }

  const getUser: AuthTypes.GetUser = async () => {
    try {
      const currentUser = await getCurrentUser()
      const {
        tokens: sessionCognito
      } = await fetchAuthSession()

      if (sessionCognito?.idToken?.payload?.email && currentUser?.userId) {
        const customerAccessToken = formattedStoreFrontTokenCognito(sessionCognito)
        setCustomerAccessToken(customerAccessToken)
        setCheckoutCustomerAccessToken(formattedCheckoutTokenCognito(sessionCognito))

        const userDate = await refetch({
          variables: { accessToken: customerAccessToken.accessToken }
        })

        await getDataUser(userDate.data, sessionCognito)
        return {
          success: true
        }
      } else {
        throw new Error(GENERIC_ERROR)
      }
    } catch (error: unknown) {
      clearTokens()
      console.error({ error })

      return {
        success: false
      }
    }
  }

  useEffect(() => {
    if (customerAccessToken && keepSessionOpened !== null && !keepSessionOpened) {
      if (nowDateUTC >= customerAccessToken.expiresAt) {
        handleSignOut()
        navigate('/', { replace: true })
        alert('Se ha cerrado la sesión por tu seguridad')
      }
    }
  }, [nowDateUTC])

  useEffect(() => {
    const timer = setInterval(() => { // Creates an interval which will update the current data every minute
      // This will trigger a re-render every component that uses the useDate hook.
      setNowDateUTC(moment.utc().format())
    }, 60 * 1000)
    return () => {
      clearInterval(timer) // Return a function to clear the timer so that it will stop being called on un-mount
    }
  }, [])

  const handleSignIn: AuthTypes.Signin = async (data: AuthTypes.SigninData) => {
    const errors: Array<string> = []

    try {
      const { isSignedIn, nextStep } = await signIn({
        username: data.email,
        password: data.password
      })

      if (isSignedIn && nextStep?.signInStep === 'DONE') {
        return { success: true }
      }

      if (ACCOUNT_NEEDS_CONFIRM === nextStep?.signInStep) {
        setEmailToConfirm({
          email: data.email,
          resentEmail: true
        })
        typeof window !== 'undefined' && navigate('/login/confirmar-cuenta')
        await dispatch(actionCreators['setInitialStateLogin']())

        return {
          success: false,
          errors: [GENERIC_ERROR]
        }
      }

      if (RESET_REQUIRED === nextStep?.signInStep) {
        setEmailToConfirm({
          email: data.email,
          resentEmail: true
        })
        typeof window !== 'undefined' && navigate('/login/reinicio-requerido')
        await dispatch(actionCreators['setInitialStateLogin']())
        return {
          success: false,
          errors: [GENERIC_ERROR]
        }
      }


      return {
        success: false,
        errors: [GENERIC_ERROR],
      }

    } catch (error: any) {
      const message =  handlerErrorCognito(error)
      errors.push(message)
      console.error(error)

      return {
        success: false,
        errors
      }
    }
  }

  const handleSignUp: AuthTypes.Signup = async (data) => {
    const errors: Array<string> = []
    try {
      const { isSignUpComplete, userId, nextStep }: any = await signUp({
        username: data.email,
        password:data.password,
        options: {
          userAttributes: {
            'custom:customAttributes': JSON.stringify({
              business_codigo_preventa: data.businessCodigoPreventa,
              business_direccion: data.businessDireccion,
              business_razon_social: data.businessRazonSocial,
              business_rfc: data.businessRfc,
              email: data.email,
              first_name: data.firstName,
              is_business: data.isBusiness,
              last_name: data.lastName,
              store: config.app.prefix,
              birthday: data.birthday,
              terms_version: ACTUAL_TERMS_VERSION
            })
          }
        }
      })

      setEmailToConfirm({
        email: data.email,
        resentEmail: false
      })

      return {
        success: true,
        result: {
          accessToken: '',
          expiresAt: '',
          isSignUpComplete,
          userId,
          nextStep
        }
      }
    } catch (error: any) {
      const message =  handlerErrorCognito(error)
      errors.push(message)
      console.error(error)

      return {
        success: false,
        errors
      }
    }
  }

  const handleConfirmSignUp: AuthTypes.ConfirmSignUp = async (data) => {
    const errors: Array<string> = []
    try {
      const { isSignUpComplete, nextStep } = await confirmSignUp({
        username: data.username,
        confirmationCode: data.authCode
      })

      if (isSignUpComplete && nextStep?.signUpStep === 'DONE') {
        return { success: true }
      }

      return {
        success: false,
        errors: [GENERIC_ERROR],
        nextStep,
        isSignUpComplete
      }
    } catch (error: any) {
      const message =  handlerErrorCognito(error)
      errors.push(message)
      console.error(error)

      return {
        success: false,
        errors
      }
    }
  }

  const resendConfirmationCode: AuthTypes.ResendConfirmationCode = async (username) => {
    const errors: Array<string> = []
    try {
      const {
        destination,
        deliveryMedium,
        attributeName
      } = await resendSignUpCode({ username })

      return {
        success: true,
        destination,
        deliveryMedium,
        attributeName
      }
    } catch (error: any) {
      const message =  handlerErrorCognito(error)
      errors.push(message)
      console.error(error)

      return {
        success: false,
        errors
      }
    }
  }

  const clearTokens = () => {
    setCustomerAccessToken(null)
    setCheckoutCustomerAccessToken(null)
    setCustomerType(null)
    setKeepSessionOpened(null)
    setUser(null)
  }

  const handleSignOut: AuthTypes.Signout = async () => {
    try {
      clearTokens()
      await signOut({ global: true })

      return {
        success: true
      }
    } catch (error: any) {
      console.error({ error })
      return {
        success: false
      }
    }
  }

  const sendEmailToResetPassword: AuthTypes.SendEmailToResetPassword = async (email) => {
    const errors: Array<string> = []
    try {
      await resetPassword({
        username: email
      })

      return {
        success: true
      }
    } catch (error: any) {
      const message =  handlerErrorCognito(error)
      errors.push(message)
      console.error(error)

      return {
        success: false,
        errors
      }
    }
  }

  const sendNewPassword: AuthTypes.SendNewPassword = async (email, password, authCode) => {
    const errors: Array<string> = []
    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: authCode,
        newPassword: password
      })

      return {
        success: true
      }
    } catch (error: any) {
      const message =  handlerErrorCognito(error)
      errors.push(message)
      console.error(error)

      return {
        success: false,
        errors
      }
    }
  }

  const handleUpdateUserAttributes: AuthTypes.UpdateUserAttributes = async (
    userAttributes
  ) => {
    const errors: Array<string> = []
    try {
      if (customerAccessToken?.accessToken) {
        await updateUserAttributes({ userAttributes })
        await getUser()

        return {
          success: true
        }
      } else {
        throw new Error(GENERIC_ERROR)
      }
    } catch (error: any) {
      console.error(error)
      if (error?.name === 'NotAuthorizedException') {
        errors.push('La sesión ha expirado. Por favor, inicia sesión nuevamente.')
      } else {
        const message =  handlerErrorCognito(error)
        errors.push(message)
      }

      return {
        success: false,
        errors
      }
    }
  }

  const validateExpirationTokens: AuthTypes.ValidateExpirationTokens = async () => {
    let isSessionExpired = false
    if (
      user?.cognito?.idToken?.payload?.exp &&
      new Date() >= new Date(user.cognito.idToken.payload.exp * 1000)
    ) {
      try {
        const currentUser = await getCurrentUser()
        const {
          tokens: sessionCognito
        } = await fetchAuthSession()
        if (currentUser?.userId && sessionCognito?.idToken?.toString) {
          return {
            success: true,
            data: `Bearer ${sessionCognito.idToken.toString()}`,
            error: ''
          }
        } else {
          isSessionExpired = true
        }
      } catch (error) {
        isSessionExpired = true
        console.error(error)
      }
    }

    if (
      isSessionExpired &&
      user?.cognito?.idToken?.payload?.exp &&
      new Date() >= new Date(user.cognito.idToken.payload.exp * 1000)
    ) {
      return {
        success: false,
        data: '',
        error: 'Tu sesión expiró. Recarga la página e inténtalo de nuevo.'
      }
    }
    return {
      success: true,
      data: '',
      error: ''
    }
  }

  const handleFederatedSignup = () => setIsNewFederatedAccount(true)

  const removeFederatedSignup = () => setIsNewFederatedAccount(false)

  return {
    user,
    signin: handleSignIn,
    signup: handleSignUp,
    signout: handleSignOut,
    sendEmailToResetPassword,
    setCustomerAccessToken,
    refetchUser: getUser,
    loadingUser: loading,
    customerType,
    confirmSignUp: handleConfirmSignUp,
    resendConfirmationCode,
    sendNewPassword,
    getUser,
    updateUserAttributes: handleUpdateUserAttributes,
    validateExpirationTokens,
    handleFederatedSignup,
    removeFederatedSignup,
    isNewFederatedAccount
  }
}

export default useProvideAuth

// Packages
import { useContext } from 'react'

// Assets
import dataContext from '../../contexts/dataContext'

// Types
import { FilterOptionsType } from '../../data/constants/filterOptions'
import { ImagePropertiesType } from '../../data/constants/imageProperties'
import { MexicoStatesType } from '../../data/constants/mexicoStates'
import { TagsDictionaryType } from '../../data/constants/tagsDictionary'
import { ContactFormFieldsType, OptionsType } from '../../data/constants/autocompleteSettings'
import { DelegationsType } from '../../data/constants/dfDelegations'
import { LandingPageSortingOptionsType } from '../../data/constants/landingPageSortingOptions'
import { CustomerMutationErrorCodesType } from '../../data/constants/customerMutationErrorCodes'
import { ProfileModalTitlesAndMessagesType } from '../../data/constants/profileModalTitlesAndMessages'
import { ConfigType } from '../../data/config'

type UseDataTypes = {
  config: ConfigType
  constants: {
    mexicoStates: MexicoStatesType
    tagsDictionary: TagsDictionaryType
    imageProperties: ImagePropertiesType
    autocompleteSettings: {
      contactFormFields: ContactFormFieldsType
      options: OptionsType
    }
    dfDelegations: DelegationsType
    customerMutationErrorCodes: CustomerMutationErrorCodesType
    filterOptions: FilterOptionsType
    landingPageSortingOptions: LandingPageSortingOptionsType
    profileModalTitlesAndMessages: ProfileModalTitlesAndMessagesType
  }
}

const useData: () => UseDataTypes = () => {
  return useContext(dataContext)
}

export default useData

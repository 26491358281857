const delegations = [
  { label: 'Selecciona una alcaldía', value: '' },
  { label: 'Álvaro Obregón', value: 'Álvaro Obregón' },
  { label: 'Azcapotzalco', value: 'Azcapotzalco' },
  { label: 'Benito Juárez', value: 'Benito Juárez' },
  { label: 'Coyoacán', value: 'Coyoacán' },
  { label: 'Cuajimalpa de Morelos', value: 'Cuajimalpa de Morelos' },
  { label: 'Cuauhtémoc', value: 'Cuauhtémoc' },
  { label: 'Gustavo A. Madero', value: 'Gustavo A. Madero' },
  { label: 'Iztacalco', value: 'Iztacalco' },
  { label: 'Iztapalapa', value: 'Iztapalapa' },
  { label: 'Magdalena Contreras', value: 'Magdalena Contreras' },
  { label: 'Miguel Hidalgo', value: 'Miguel Hidalgo' },
  { label: 'Milpa Alta', value: 'Milpa Alta' },
  { label: 'Tláhuac', value: 'Tláhuac' },
  { label: 'Tlalpan', value: 'Tlalpan' },
  { label: 'Venustiano Carranza', value: 'Venustiano Carranza' },
  { label: 'Xochimilco', value: 'Xochimilco' }
]

export default delegations

export type DelegationsType = typeof delegations
